<template>
  <div>
    <sui-container text>
      <h1 class="ui header">
        Zentrale Heimplatzanmeldung der Stadt Konstanz
      </h1>
    </sui-container>

    <sui-container text v-if="flash">
      <sui-message success>
        {{ message }}
      </sui-message>
    </sui-container>

    <sui-container text>
      <sui-message>
        <sui-message-content>
          <p>Sehr geehrte Nutzerinnen und Nutzer der zentralen Heimplatzanmeldung,</p>

          <p>die Suche nach einem Pflegeheimplatz ist eine große Herausforderung und mit hohem
            Zeitaufwand verbunden.</p>

          <p>Die Stadt Konstanz unterstützt Sie dabei mit der Möglichkeit der zentralen
            Heimplatzanmeldung. Sie können sich einfach digital bei den Konstanzer
            Pflegeheimeinrichtungen anmelden.</p>

          <p>Die zentrale Heimplatzanmeldung ist eine Anmeldeplattform. <b>Die Vergabe der
            Heimplätze erfolgt ausschließlich durch die von Ihnen ausgewählten
            Einrichtungen.</b></p>

          <p>Sollten Sie sonstige Fragen zum Älterwerden in Konstanz haben, klicken Sie bitte
            <a href="https://www.konstanz.de/leben+in+konstanz/sozialleistungen+_+hilfen/beratung+_+unterstuetzung/altenhilfe-beratung"
               target="_blank"
               rel="noopener noreferrer">hier</a>.</p>
        </sui-message-content>
      </sui-message>
    </sui-container>

    <sui-container text class="choice">
      <h3 class="ui header">Sie möchten eine neue Heimplatzanmeldung vornehmen?</h3>
      <div class="ui grid">
        <div class="center aligned one column row">
          <div class="column">
            <router-link to="/application"
                         class="ui large primary button custom-button">
              Heimplatzanmeldung starten
            </router-link>
          </div>
        </div>
      </div>
    </sui-container>

    <sui-container text class="choice">
      <h3>Sie haben bereits eine Heimplatzanmeldung vorgenommen?</h3>
      <div class="ui grid">
        <div class="center aligned one column row">
          <div class="column">
            <router-link to="/loadExistingApplication"
                         class="ui large basic primary centered button custom-button">
              Vorhandene Heimplatzanmeldung einsehen / bearbeiten
            </router-link>
          </div>
        </div>
      </div>
    </sui-container>
  </div>

</template>

<script>
  export default {
    props: {
      flash: {
        type: String
      }
    },
    computed: {
      message() {
        if (this.flash === 'applicationDeleted') {
          return 'Ihre Heimplatzanmeldung wurde gelöscht.'
        }
        return ''
      }
    },
    mounted(){
      this.$store.commit('resetState');
    }
  }
</script>

<style>
  .choice {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }

  .custom-button {
    width: 70%;
  }
</style>
