<template>
  <div>
    <div v-if="!validationError">
      <sui-container text>
        <h1 class="ui header">Ihre Heimplatzanmeldung</h1>
        <div class="sub header">
          Chiffre-Nr. {{ processInstance.applicationId }}
        </div>

        <h2 class="ui header">Status Ihrer Heimplatzanmeldung</h2>

        <institution-status-table :items="institutionStatuses" />
      </sui-container>

      <sui-container text>
        <p>
          Ihre Anmeldung ist am
          <b>{{ processInstance.dateSubmitted | date("DD.MM.YYYY") }}</b> bei
          uns eingegangen.
        </p>

        <p v-if="!showExtensionHint()">
          Ihre Anmeldung ist gültig bis zum
          <b>{{ processInstance.validUntil | date("DD.MM.YYYY") }}</b
          >. Dann müssen Sie Ihre Anmeldung verlängern. Sie werden
          rechtzeitig per Mail an den Ablauf der Jahresfrist erinnert.
        </p>

        <extend-application-box
          v-if="showExtensionHint()"
        />

        <sui-message v-if="applicationExtended" success>
          <sui-message-content>
            <p>
              <i class="check icon"></i> Ihre Anmeldung wurde verlängert.
            </p>
          </sui-message-content>
        </sui-message>
      </sui-container>

      <sui-container text>
        <div class="ui divided items">
          <div class="item">
            <div class="middle aligned content">
              <sui-grid>
                <sui-grid-column :width="6">
                  <a
                    class="ui primary fluid button"
                    @click="routeToApplicationForm()"
                  >
                    Anmeldung bearbeiten
                  </a>
                </sui-grid-column>
                <sui-grid-column :width="10">
                  <p>
                    Hier können Sie die Angaben in Ihrer Heimplatzanmeldung
                    aktualisieren.
                  </p>
                </sui-grid-column>
              </sui-grid>
            </div>
          </div>
          <div class="item">
            <div class="middle aligned content">
              <sui-grid>
                <sui-grid-column :width="6">
                  <a
                    class="ui primary fluid button"
                    @click="confirmDeleteApplication()"
                  >
                    Bedarf abmelden
                  </a>
                </sui-grid-column>
                <sui-grid-column :width="10">
                  <p>
                    Falls der Bedarf an einem Heimplatz nicht mehr gegeben ist,
                    können Sie hier Ihre Anmeldung löschen.
                  </p>
                </sui-grid-column>
              </sui-grid>
            </div>
          </div>
          <div class="item">
            <div class="middle aligned content">
              <sui-grid>
                <sui-grid-column :width="6">
                  <a
                    class="ui primary fluid button"
                    @click="renewApplication()"
                  >
                    Bedarf verlängern
                  </a>
                </sui-grid-column>
                <sui-grid-column :width="10">
                  <p>
                    Wenn Sie weiterhin Bedarf an der Unterbringung in einem Heim haben, können Sie hier Ihre Anmeldung verlängern.
                  </p>
                </sui-grid-column>
              </sui-grid>
            </div>
          </div>
        </div>
      </sui-container>

      <sui-container text>
        <h3>Brauchen Sie Hilfe oder haben Sie noch Fragen?</h3>

        <p>
          Die Abteilung Altenhilfe der Stadt Konstanz erreichen Sie unter
          <a href="tel:+4975319002408">07531 900 2408</a>.
        </p>
      </sui-container>
    </div>

    <vue-semantic-modal v-model="showConfirmDeleteApplicationModal">
      <p slot="header">Heimplatzbedarf abmelden</p>

      <template slot="content">
        <div class="description">
          <p>
            Sie haben keinen Bedarf mehr an einem Heimplatz bei den angefragten
            Pflegeheimen? Dann löschen Sie bitte untenstehend Ihre Anmeldung für
            einen Heimplatz für alle angefragten Pflegeheime. Ihre
            personenbezogenen Daten werden damit ebenfalls gelöscht.
          </p>

          <p v-if="showAdditionalConfirmDeleteApplicationMessage">
            Sind Sie sicher?
          </p>
        </div>
      </template>

      <template slot="actions">
        <div
          class="ui black deny button"
          @click="closeConfirmDeleteApplicationModal()"
        >
          <span v-if="!showAdditionalConfirmDeleteApplicationMessage"
            >Abbrechen</span
          >
          <span v-else>Nein, abbrechen</span>
        </div>
        <div
          v-if="!showAdditionalConfirmDeleteApplicationMessage"
          class="ui primary labeled icon button"
          @click="showAdditionalConfirmDeleteApplicationMessage = true"
        >
          Anmeldung löschen
          <i class="remove icon"></i>
        </div>
        <div
          v-else
          class="ui primary labeled icon button"
          @click="deleteApplication()"
        >
          Ja, Anmeldung löschen
          <i class="remove icon"></i>
        </div>
      </template>
    </vue-semantic-modal>

    <vue-semantic-modal v-model="showApplicationSelectedNoticeModal">
      <p slot="header">Bearbeiten nicht möglich</p>

      <template slot="content">
        <div class="description">
          <p>
            Derzeit können Sie Ihre Anmeldung leider nicht nachbearbeiten, da
            eine Einrichtung Sie für einen Heimplatz ausgewählt hat. Die
            entsprechende Einrichtung wird sich zeitnah bei Ihnen melden.
          </p>
        </div>
      </template>

      <template slot="actions">
        <div
          class="ui primary button"
          @click="showApplicationSelectedNoticeModal = false"
        >
          OK
        </div>
      </template>
    </vue-semantic-modal>

    <vue-semantic-modal v-model="showNoApplicationFoundModal">
      <p slot="header">Keine Anmeldung gefunden</p>

      <template slot="content">
        <div class="description">
          <p>
            Für die übermittelte Kombination aus Chiffre-Nr. und Geburtsdatum wurde keine Anmeldung gefunden.
          </p>
        </div>
      </template>

      <template slot="actions">
        <div
          class="ui primary button"
          @click="goBack()"
        >
          OK
        </div>
      </template>
    </vue-semantic-modal>

  </div>
</template>

<script>
import VueSemanticModal from "vue-semantic-modal";
import RetirementHomeService from "../../services/retirementHomeService";

import ExtendApplicationBox from "@/components/dashboard/ExtendApplicationBox";
import InstitutionStatusTable from "@/components/dashboard/InstitutionStatusTable";

export default {
  components: {
    ExtendApplicationBox,
    InstitutionStatusTable,
    VueSemanticModal,
  },
  props: {
    applicationId: {
      type: String,
    },
    birthDate: {
      type: String,
    },
    processInstanceId: {
      type: String,
    },
  },
  data() {
    return {
      validationError: false,
      processInstance: {
        applicationId: null,
        dateSubmitted: null,
        validUntil: null,
      },
      institutionStatuses: [],
      extendApplicationButtonDisabled: false,
      applicationExtended: false,
      showApplicationSelectedNoticeModal: false,
      showConfirmDeleteApplicationModal: false,
      showAdditionalConfirmDeleteApplicationMessage: false,
      showNoApplicationFoundModal: false
    };
  },
  methods: {
    mapDateFormat(dateString) {
      var int = dateString.split(".")
      var date = new Date(int[2], int[1]-1, int[0])
      var monthAsInt = parseInt(date.getMonth().toString()) + 1
      var month = (monthAsInt.toString().length === 1) ? "0"+(monthAsInt) : (monthAsInt)
      var day = (date.getDate().toString().length === 1) ? "0"+date.getDate() : date.getDate()
      return date.getFullYear() + "-" + month + "-" + day
    },
    goBack(){
      this.showNoApplicationFoundModal = false;
      this.$router.push({
        path: '/loadExistingApplication'
      })
    },
    routeToApplicationForm() {
      const statusesNotAllowedToUpdateApplication = [
        "PATIENT_SELECTED",
        "APPLICANT_DID_CONFIRM",
        "APPLICANT_DID_REJECT",
      ];

      const applicationSelected = (results) =>{
        const statuses = results.map(elem => elem.status);
        return statusesNotAllowedToUpdateApplication.some(status => statuses.includes(status))
      }
        

      RetirementHomeService.retrieveApplicationStatus(
        this.applicationId,
        this.birthDate
      ).then(res => {
        let allowedToUpdate = !(applicationSelected(res.data.results));
        if(allowedToUpdate){
          RetirementHomeService.retrieveApplication(
            this.applicationId,
            this.birthDate
          ).then((response) => {
            response.data.application.documents.documents= [];
            this.$store.state.application = response.data.application;
            this.$store.state.passedApplicationId = this.applicationId;
            this.$store.state.passedBirthDate = this.birthDate;
            this.$router.push({
              name: "application",
              params: {
                processInstanceId: response.data.processInstanceId
              },
            });
          }); 
        }else{
          this.showApplicationSelectedNoticeModal = true;
        }
      }); 
    },
    showExtensionHint() {
      return (
        this.processInstance.validUntil &&
        new Date(this.processInstance.validUntil) < new Date(Date.now())
      );
    },
    renewApplication(){
      RetirementHomeService.renewApplication(        
        this.processInstance.applicationId,
        this.birthDate
        ).then(() => {
          this.retrieveData();
          this.applicationExtended = true;
        });
    },
    deleteApplication() {
      this.showConfirmDeleteApplicationModal = false;
      RetirementHomeService.deleteApplication(
        this.processInstance.applicationId,
        this.birthDate
      ).then(() => {
        this.$router.push({
          name: "home",
          params: {
            flash: "applicationDeleted",
          },
        });
      });
    },
    confirmDeleteApplication() {
      this.showConfirmDeleteApplicationModal = true;
    },
    closeConfirmDeleteApplicationModal() {
      this.showConfirmDeleteApplicationModal = false;
      this.showAdditionalConfirmDeleteApplicationMessage = false;
    },
    validateProps() {
      return this.applicationId && this.birthDate;
    },
    retrieveData(){
      RetirementHomeService.retrieveApplicationStatus(
        this.applicationId,
        this.birthDate
      ).then((response) => {
        this.processInstance.dateSubmitted = response.data.dateSubmitted;
        this.processInstance.validUntil = response.data.validUntil;
        this.processInstance.applicationId = response.data.applicationId;
        this.institutionStatuses = response.data.results;
      }).catch(() => {
        this.showNoApplicationFoundModal = true;
      });
    }
  },
  mounted() {
    if (!this.validateProps()) {
      this.validationError = true;
      return;
    }
    //SKH-409 Map textDate to suitable Date format
    this.birthDate = this.mapDateFormat(this.birthDate)
    this.retrieveData();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}

.ui.modal {
  position: relative;
  margin: 0 auto !important;
  top: 30%;
}
</style>
