<template>
  <vl-map
    :load-tiles-while-animating="true"
    :load-tiles-while-interacting="true"
    style="height: 450px"
  >
    <vl-view :zoom.sync="zoom" :center.sync="center" :rotation.sync="rotation"></vl-view>

    <vl-layer-tile id="osm">
      <vl-source-osm></vl-source-osm>
    </vl-layer-tile>

    <vl-layer-vector id="points">
      <vl-source-vector :features="features"></vl-source-vector>
      <vl-style-func :factory="createCustomStyle"></vl-style-func>
    </vl-layer-vector>
  </vl-map>
</template>

<script>
import { createStyle } from "vuelayers/lib/ol-ext";
import { Icon } from "ol/style/";

// Default values for KN-City
const longitude = 9.185;
const latitude = 47.667;

export default {
  data() {
    return {
      zoom: 13,
      center: [longitude, latitude],
      rotation: 0,
      features: []
    };
  },
  props: ["institutionInfos"],
  watch: {
    institutionInfos: function() {
      this.bindFeatures();
    }
  },
  methods: {
    bindFeatures() {
      for (let i = 0; i < this.institutionInfos.length; i++) {
        let institution = this.institutionInfos[i];
        this.features.push({
          id: institution.name,
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [institution.longitude, institution.latitude]
          }
        });
      }
    },
    createCustomStyle() {
      return feature => {
        return createStyle({
          image: new Icon({
            src: "map-marker.png",
            scale: "0.2"
          }),
          strokeColor: "black",
          textAlign: "left",
          textScale: 1.3,
          text: "   " + feature.getId() // Spaces needed for horizontal offset,
        });
      };
    },
    mounted(){
      this.bindFeatures();
    } 
  }
};
</script>