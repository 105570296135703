<template>
  <div>
    <div class="ui steps" :class="stepsClasses">
      <sui-step v-for="(step, index) of steps"
                :key="index"
                :active="currentStep === index">
        <sui-step-content>
          <sui-step-title v-html="step.label"
                          @click="isStepTitleClickable(index) ? goTo(index) : null"
                          :style="{ cursor: isStepTitleClickable(index) ? 'pointer' : 'auto' }"/>
        </sui-step-content>
      </sui-step>
    </div>

    <sui-segment :key="currentSlot">
      <slot :name="currentSlot"/>
    </sui-segment>

    <sui-segment basic clearing>
      <sui-button v-if="backEnabled"
                  id="formStepPrevious"
                  floated="left"
                  @click="goBack">
        {{ previousStepLabel }}
      </sui-button>
      <sui-button v-if="currentStep !== steps.length - 1"
                  id="formStepNext"
                  floated="right"
                  primary
                  @click="goNext">
        {{ nextStepLabel }}
      </sui-button>
      <sui-button v-else
                  id="formStepFinish"
                  floated="right"
                  primary
                  :class="{'disabled': options[currentStep].nextDisabled}"
                  @click="finish">
        {{ finalStepLabel }}
      </sui-button>
    </sui-segment>
  </div>
</template>

<script>
  export default {
    name: 'FormSteps',
    props: {
      steps: {
        type: Array,
        required: true
      },
      stepsClasses: Array,
      previousStepLabel: { default: 'Zurück' },
      nextStepLabel: { default: 'Weiter' },
      finalStepLabel: { default: 'Abschicken' },
      onNext: {},
      onBack: {},
    },
    watch: {
      steps: {
        handler() {
          this.parseOptions();
        },
        immediate: true,
      }
    },
    data() {
      return {
        currentStep: 0,
        options: [],
      };
    },
    computed: {
      currentSlot() {
        return this.steps[this.currentStep].slot;
      },
      backEnabled() {
        return this.currentStep > 0;
      }
    },
    methods: {
      isStepTitleClickable(index) {
        return index <= this.currentStep;
      },
      goNext() {
        if (typeof this.onNext === 'function' && !this.onNext(this.currentStep)) {
          return;
        }
        if (this.currentStep < this.steps.length - 1) {
          this.currentStep++;
        }
      },
      goBack() {
        if (typeof this.onBack === 'function' && !this.onBack(this.currentStep)) {
          return;
        }
        if (this.currentStep > 0) {
          this.currentStep--;
        }
      },
      goTo(step) {
        if (Number.isInteger(step) &&
          step < this.steps.length &&
          step >= 0) {
          this.currentStep = step;
        }
      },
      finish() {
        this.$emit('submit');
      },
      parseOptions() {
        this.options = this.steps.map((step) => step.options || {});
      }
    }
  };
</script>
