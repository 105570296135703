<template>
  <div>
    <h2 class="ui header">Ihre Anmeldung für einen Heimplatz wurde erfolgreich übermittelt!</h2>
    <div is="sui-message" class="big positive">
      Ihre Chiffre-Nummer:
      <span id="applicationId">
        {{ applicationId }}
      </span>
    </div>
    <p>
      <a :href="pdfUrl">
        <b>Anmeldeunterlagen jetzt herunterladen und ausdrucken</b>
      </a>
    </p>

  <div is="sui-message" class="negative" v-if="fileUploadFailed">
    <p><b>Der Upload ihrer Dokumente ist leider fehlgeschlagen.</b> 
      Sie können die Dokumente ihrer Anmeldung beifügen wenn Sie die Anmeldung bearbeiten. 
      Befolgen Sie dazu unten aufgeführte Schritte.
    </p>
  </div>

    <h3 class="ui header">Wie geht es weiter?</h3>
    <p>
      Wir haben Ihre Anmeldung für einen Heimplatz erhalten und diese an die von Ihnen gewählten
      Pflegeheime weitergeleitet. Sie werden direkt durch das jeweilige Pflegeheim kontaktiert,
      sobald ein geeigneter Platz zu Verfügung steht.
    </p>

    <h3 class="ui header">Verlängerung Ihrer Anmeldung</h3>
    <p>
      Ihre Anmeldung besteht für zwölf Monate. Vor Ablauf des Zeitraums erhalten Sie eine
      Erinnerungsmail und können Ihren Bedarf weiter bestätigen oder die Anmeldung löschen.
    </p>

    <h3 class="ui header">Änderung Ihrer Angaben</h3>
    <p>
      Sie können jederzeit den Stand Ihrer Anmeldung einsehen oder Angaben nachträglich ändern.
      Rufen Sie dazu online die Zentrale Heimplatzanmeldung auf und klicken Sie auf „Vorhandene
      Heimplatzanmeldung einsehen&nbsp;/ bearbeiten“. Sie benötigen dafür lediglich Ihre
      Chiffrenummer und das Geburtsdatum der angemeldeten Person.</p>

    <h3 class="ui header">Weitere Unterstützung</h3>
    <p>
      Sollten Sie zum Anmeldeverfahren Fragen haben, können Sie sich gerne an Frau Stumpf, Abteilung
      Altenhilfe Stadt Konstanz, Tel.: <a href="tel:+4975319002408">07531 9002408</a> wenden.
    </p>
  </div>
</template>

<script>

  import RetiremenHomeService from '@/services/retirementHomeService';

  export default {
    props: {
      applicationId: {
        type: String,
        required: true
      },
      patientDob: {
        type: String,
        required: true
      },
      fileUploadFailed: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      //TODO remove
      downloadPdf() {
        RetiremenHomeService.retrieveSummaryPdf(this.applicationId, this.patientDob).then((response) => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(new Blob([response.data]));
                link.download = "PDFAusdruckHeimplatzanmeldung.pdf";
                link.click();
                URL.revokeObjectURL(link.href);
        })
          // eslint-disable-next-line no-console
        .catch(console.error)
      }
    },
    computed: {
      pdfUrl: function () {
        return "/heimplatz-bpm/rest/summary/pdf?applicationId="+this.applicationId+"&bd="+this.patientDob;
      }
    }
  }
</script>

<style>
  #applicationId {
    font-weight: bold;
  }
</style>
