<template>
  <table class="ui celled table">
    <thead>
      <tr>
        <th>Pflegeheim</th>
        <th>Status</th>
        <th>Status vom</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="institution in items" :key="institution.id">
        <td data-label="Pflegeheim">
          {{ institution.name }}
        </td>
        <td data-label="Status">
          {{formatStatus( institution.status )}}
          <sui-popup v-if="institution.status == 'APPLICATION_DEFFERED'">
              <sui-popup-content>
                <p>Dieser Status bedeutet, dass Ihnen von einem anderen Heim ein Platz angeboten wurde. 
                  Bis Sie diesen Platz annehmen oder ablehnen ist Ihre Anmeldung für andere Heime nicht einsehbar.</p>
              </sui-popup-content>
              <i size="10" slot="trigger"
                 class="tiny circular grey inverted question icon"
                 style="margin-left: 5px"></i>
            </sui-popup>

            <sui-popup v-if="institution.status == 'APPLICATION_CHECK_RESET'">
              <sui-popup-content>
                <p>Nachdem Sie Ihre Anmeldung bearbeitet haben, kann eine erneute Prüfung durch die Heime notwendig werden</p>
              </sui-popup-content>
              <i size="10" slot="trigger"
                 class="tiny circular grey inverted question icon"
                 style="margin-left: 5px"></i>
            </sui-popup>
        </td>
        <td data-label="Status vom">
          {{ institution.statusDate | date("DD.MM.YYYY") }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  mounted(){
    this.manipulateStatusesForDisplay();
  },
  methods: {
    manipulateStatusesForDisplay(){
      let statuses = this.items.map(institution => institution.status);
      if(statuses.includes("PATIENT_SELECTED")){
        this.items.forEach(elem => {
          if(elem.status != "PATIENT_SELECTED"){
            elem.status = "APPLICATION_DEFFERED"
          }
        });
      }
    },
    formatStatus(status) {
      var statusTranslations = new Map();
      statusTranslations.set("CHECKING", "In Prüfung");
      statusTranslations.set("APPLICATION_SUITABLE", "Auf der Warteliste");
      statusTranslations.set("APPLICATION_REJECTED", "Anmeldung abgelehnt");
      statusTranslations.set("PATIENT_SELECTED", "Platz angeboten");
      statusTranslations.set("APPLICATION_DEFFERED", "Zurückgestellt");
      statusTranslations.set("APPLICANT_DID_CONFIRM", "Platz angenommen");
      statusTranslations.set("APPLICANT_DID_REJECT", "Platz abgelehnt");
      statusTranslations.set("APPLICATION_CHECK_RESET", "Erneute Prüfung");
      return statusTranslations.get(status);
    },
  },
};
</script>
