import Vue from 'vue';
import Vuelidate from 'vuelidate';
import PortalVue from 'portal-vue';
import VueScreen from 'vue-screen';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SuiVue from 'semantic-ui-vue';
import '../public/semantic/dist/semantic.min.css';

import { dateFilter } from 'vue-date-fns';

import VueLayers from 'vuelayers'
import 'vuelayers/lib/style.css'

import App from './App.vue';
import router from './router';
import storeConfig from './store/store-config';

Vue.use(Vuex);

const store = new Vuex.Store(storeConfig,{
  plugins: [createPersistedState({
    storage: window.sessionStorage,
})]
});

Vue.config.productionTip = false;
Vue.use(SuiVue);
Vue.use(Vuelidate);
Vue.use(PortalVue);
Vue.use(VueScreen, 'semantic-ui');

Vue.use(VueLayers, {
  dataProjection: 'EPSG:4326',
})

Vue.filter('date', dateFilter);

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
