<template>
  <div>
    <h2 class="ui dividing header">Anmeldende Person</h2>

    <sui-form-fields>
      <sui-form-field>
        <label>Die anmeldende Person ist identisch mit der betroffenen Person.</label>
        <sui-form-fields>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="applicant.identicalPerson.true"
                     type="radio"
                     v-model="applicant.identicalPerson"
                     @change="identicalPersonChanged"
                     :value="true"/>
              <label for="applicant.identicalPerson.true">Ja</label>
            </div>
          </div>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="applicant.identicalPerson.false"
                     type="radio"
                     v-model="applicant.identicalPerson"
                     @change="identicalPersonChanged"
                     :value="false"/>
              <label for="applicant.identicalPerson.false">Nein</label>
            </div>
          </div>
        </sui-form-fields>
      </sui-form-field>
    </sui-form-fields> 
    <div v-if="!applicant.identicalPerson">    
      <sui-form-fields>
        <sui-form-field :width="5" required
                        :class="{ error: $v.applicant.firstName.$error }">
          <label>Vorname</label>
          <sui-input
            placeholder="Vorname"
            v-model="applicant.firstName"/>
          <small v-if="$v.applicant.firstName.$error">Bitte geben Sie den Vornamen ein.</small>
        </sui-form-field>
        <sui-form-field :width="5" required
                        :class="{ error: $v.applicant.lastName.$error }">
          <label>Nachname</label>
          <sui-input
            placeholder="Nachname"
            v-model="applicant.lastName"/>
          <small v-if="$v.applicant.lastName.$error">Bitte geben Sie den Nachnamen ein.</small>
        </sui-form-field>
      </sui-form-fields>

      <sui-form-fields>
        <sui-form-field :width="5" required
                        :class="{ error: $v.applicant.relationship.$error }">
          <label>Verhältnis zum Betroffenen</label>
          <sui-dropdown
            fluid
            :options="relationshipOptions"
            placeholder="Bitte auswählen"
            selection
            v-model="applicant.relationship"/>
          <small v-if="$v.applicant.relationship.$error">
            Bitte wählen Sie das Verhältnis zum Betroffenen aus.
          </small>
        </sui-form-field>
        <sui-form-field :width="5" required
                        :class="{ error: $v.applicant.authorisedPerson.$error }">
          <label>Bevollmächtigte Person oder gesetzliche Betreuung</label>
          <sui-form-fields inline>
            <div class="field">
              <div class="ui radio checkbox">
                <input id="applicant.authorisedPerson.true"
                       type="radio"
                       v-model="applicant.authorisedPerson"
                       :value="true"/>
                <label for="applicant.authorisedPerson.true">Ja</label>
              </div>
            </div>
            <div class="field">
              <div class="ui radio checkbox">
                <input id="applicant.authorisedPerson.false"
                       type="radio"
                       v-model="applicant.authorisedPerson"
                       :value="false"/>
                <label for="applicant.authorisedPerson.false">Nein</label>
              </div>
            </div>
          </sui-form-fields>
          <div v-if="$v.applicant.authorisedPerson.$dirty">
            <small v-if="$v.applicant.authorisedPerson.$error">
              Bitte geben Sie an, ob Sie Bevollmächtigte(r) oder gesetzliche/r Betreuer der betroffenen Person sind. 
            </small>
          </div>
        </sui-form-field>
        <sui-form-field :width="5" required
                        :class="{ error: $v.applicant.legalCare.$error }">
          <label>Gesetzliche Betreuung wurde angeregt</label>
          <sui-form-fields inline>
            <div class="field">
              <div class="ui radio checkbox">
                <input id="applicant.legalCare.true"
                       type="radio"
                       v-model="applicant.legalCare"
                       :value="true"/>
                <label for="applicant.legalCare.true">Ja</label>
              </div>
            </div>
            <div class="field">
              <div class="ui radio checkbox">
                <input id="applicant.legalCare.false"
                       type="radio"
                       v-model="applicant.legalCare"
                       :value="false"/>
                <label for="applicant.legalCare.false">Nein</label>
              </div>
            </div>
          </sui-form-fields>
          <div v-if="$v.applicant.legalCare.$dirty">
            <small v-if="$v.applicant.legalCare.$error">
              Bitte geben Sie an, ob die gesetzliche Betreuung angeregt wurde.
            </small>
          </div>
        </sui-form-field>
      </sui-form-fields>

      <sui-form-fields>
        <sui-form-field>
          <label>Sind Sie die Kontaktperson für die betroffene Person?</label>
          <sui-form-fields>
            <div class="field">
              <div class="ui radio checkbox">
                <input id="showContactPerson.true"
                       type="radio"
                       v-model="showContactPerson"
                       @change="showContactPersonChanged"
                       :value="false"/>
                <label for="showContactPerson.false">Ja</label>
              </div>
            </div>
            <div class="field">
              <div class="ui radio checkbox">
                <input id="showContactPerson.false"
                       type="radio"
                       v-model="showContactPerson"
                       @change="showContactPersonChanged"
                       :value="true"/>
                <label for="showContactPerson.true">Nein</label>
              </div>
            </div>
          </sui-form-fields>
        </sui-form-field>
      </sui-form-fields>

      <sui-form-fields v-if="showContactPerson">
        <sui-form-field :width="5" required
                        :class="{ error: $v.applicant.contactPersonFirstName.$error }">
          <label>Kontaktperson Vorname</label>
          <sui-input
            placeholder="Vorname"
            v-model="applicant.contactPersonFirstName"/>
          <small v-if="$v.applicant.contactPersonFirstName.$error">Bitte geben Sie den Vornamen der Kontaktperson an.</small>
        </sui-form-field>
        <sui-form-field :width="5" required
                        :class="{ error: $v.applicant.contactPersonLastName.$error }">
          <label>Kontaktperson Nachname</label>
          <sui-input
            placeholder="Nachname"
            v-model="applicant.contactPersonLastName"/>
          <small v-if="$v.applicant.contactPersonLastName.$error">Bitte geben Sie den Nachnamen der Kontaktperson an.</small>
        </sui-form-field>
      </sui-form-fields>

      <div v-if="showContactPerson">
        <h5>Telefon der Kontaktperson<span style="color: #db2828; margin: -.2em 0 0 .2em">*</span></h5>
      </div>

      <div v-if="showContactPerson"> 
        <sui-form-fields v-for="(contactPersonPhoneNumber, index) in $v.applicant.contactPersonPhoneNumbers.$each.$iter" :key="index">

          <sui-form-field :width="3" required :class="{ error: $v.applicant.contactPersonPhoneNumbers.$error }">
            <sui-dropdown
              fluid
              :options="phoneTypeOptions"
              placeholder="Bitte auswählen"
              selection
              v-model="contactPersonPhoneNumber.type.$model"/>
          </sui-form-field>

          <sui-form-field :width="5" required
            :class="{ error: $v.applicant.contactPersonPhoneNumbers.$error }">
            <sui-input placeholder="Telefonnummer"
            type="tel"
              v-model="contactPersonPhoneNumber.phone.$model"> </sui-input>
            <small v-if="$v.applicant.contactPersonPhoneNumbers.$error">Bitte geben Sie die Telefonnummer der Kontaktperson an.</small>
          </sui-form-field>

          <sui-form-field v-if="index >= 1">
            <sui-button @click="removePhoneNumber(true, index)">Telefonnummer löschen</sui-button>
          </sui-form-field>
        </sui-form-fields>

          <sui-form-field>
            <sui-button @click="addPhoneNumber(true)">Weitere Telefonnummer angeben</sui-button>
          </sui-form-field>
        <sui-form-field  :width="8">
           <label>Hinweise zur Erreichbarkeit der Kontaktperson</label>
          <textarea rows="2"
                    v-model="applicant.contactPersonPhoneRemarkText"></textarea>
        </sui-form-field>
        
      </div>

      <sui-form-fields>
        <sui-form-field :width="7" required
                        :class="{ error: $v.applicant.differentAddress.$error }">
          <label>Weicht die Adresse von der Adresse der betroffenen Person ab?</label>
          <sui-form-fields inline>
            <div class="field">
              <div class="ui radio checkbox">
                <input id="applicant.differentAddress.true"
                       type="radio"
                       v-model="applicant.differentAddress"
                       @change="differentAddressChanged"
                       :value="true"/>
                <label for="applicant.differentAddress.true">Ja</label>
              </div>
            </div>
            <div class="field">
              <div class="ui radio checkbox">
                <input id="applicant.differentAddress.false"
                       type="radio"
                       v-model="applicant.differentAddress"
                       @change="differentAddressChanged"
                       :value="false"/>
                <label for="applicant.differentAddress.false">Nein</label>
              </div>
            </div>
          </sui-form-fields>
          <div v-if="$v.applicant.differentAddress.$dirty">
            <small v-if="$v.applicant.differentAddress.$error">
              Bitte geben Sie an, ob die Anschrift der anmeldenden Person identisch mit der
              Anschrift der betroffenen Person ist.
            </small>
          </div>
        </sui-form-field>
      </sui-form-fields>

      <div v-if="applicant.differentAddress"> 
      <sui-form-fields>    
          <sui-form-field :width="2" required
                          :class="{ error: $v.applicant.postalCode.$error }">
            <label>PLZ</label>
            <sui-input
              placeholder="PLZ"
              v-model="applicant.postalCode"/>
            <small v-if="$v.applicant.postalCode.$error">Bitte geben Sie die PLZ ein.</small>
          </sui-form-field>
          <sui-form-field :width="4" required
                          :class="{ error: $v.applicant.city.$error }">
            <label>Ort</label>
            <sui-input
              placeholder="Ort"
              v-model="applicant.city"/>
            <small v-if="$v.applicant.city.$error">Bitte geben Sie den Ort ein.</small>
          </sui-form-field>
          <sui-form-field :width="5" required
                          :class="{ error: $v.applicant.address.$error }">
            <label>Straße und Hausnummer</label>
            <sui-input
              placeholder="Straße und Hausnummer"
              v-model="applicant.address"/>
            <small v-if="$v.applicant.address.$error">Bitte geben Sie die Straße und Hausnummer an.</small>
        </sui-form-field>
      </sui-form-fields>
      </div>

      <sui-form-fields>
        <sui-form-field :width="6"
                        :class="{ error: $v.applicant.email.$error }">
          <label>
            E-Mail
            <sui-popup :content="emailInfo"
                       wide="very"
                       position="center top">
              <i slot="trigger"
                 class="small circular grey inverted question icon"
                 style="margin-left: 5px"></i>
            </sui-popup>
          </label>
          <sui-input
            placeholder="E-Mail"
            v-model="applicant.email"/>
          <small v-if="$v.applicant.email.$error">
            Der Aufbau der angegebenen E-Mail-Adresse ist ungültig. Bitte überprüfen Sie Ihre
            Eingabe.
          </small>
        </sui-form-field>
      </sui-form-fields>

      <!-- SKH-364 --> 
      <div v-if="$screen.width <= 800">
        <div class="ui hidden divider"></div>
      </div>
      
      <sui-form-fields>
        <sui-form-field :width="11">
          <label>
            Wirkungskreis der Betreuung/Vollmacht
            <sui-popup>
              <sui-popup-content>
                <p>Mehrfachauswahl möglich sofern nicht &quot;vollumfänglich&quot; ausgewählt ist</p>
              </sui-popup-content>
              <i slot="trigger"
                 class="small circular grey inverted question icon"
                 style="margin-left: 5px"></i>
            </sui-popup>
          </label>
          <sui-dropdown
            fluid
            :options="authorisationOptions"
            placeholder="Bitte auswählen"
            multiple
            selection
            v-model="applicant.authorisations"/>
        </sui-form-field>
      </sui-form-fields>

      <h3 class="ui header">
        Telefon der anmeldenden Person<span style="color: #db2828; margin: -.2em 0 0 .2em">*</span><!-- SKH-247 -->
      </h3>

      <sui-form-fields v-for="(phone, index) in $v.applicant.phoneNumbers.$each.$iter" :key="index">
        <sui-form-field :width="3" required :class="{ error: $v.applicant.phoneNumbers.$error }">
          <sui-dropdown
            fluid
            :options="phoneTypeOptions"
            placeholder="Bitte auswählen"
            selection
            v-model="phone.type.$model"/>
        </sui-form-field> 

        <sui-form-field :width="5" required :class="{ error: $v.applicant.phoneNumbers.$error }">
          <sui-input
            type="tel"
            placeholder="Telefon"
            v-model="phone.phone.$model"/>
            <small v-if="$v.applicant.phoneNumbers.$error">
              Bitte geben Sie eine gültige Telefonnummer ein.
            </small>
        </sui-form-field>

        <sui-form-field v-if="index >= 1">
          <sui-button @click="removePhoneNumber(false, index)">Telefonnummer löschen</sui-button>
        </sui-form-field>
      </sui-form-fields>

      <sui-form-field>
        <sui-button @click="addPhoneNumber(false)">Weitere Telefonnummer angeben</sui-button>
      </sui-form-field>

      <sui-form-field :width="8">
          <label>Hinweise zur Erreichbarkeit / Hinweise zur Kontaktperson</label>
          <textarea rows="2"
                    v-model="applicant.phoneRemarkText"></textarea>
      </sui-form-field>
    </div>
  </div>
</template>

<script>
  import { requiredIf, minLength, maxLength, email } from "vuelidate/lib/validators";

  import { initialApplicationState } from "@/application";
  import selectOptions from '@/assets/selectOptions';

  export default {
    name: 'ApplicantSection',
    props: {
      value: {
        type: Object,
        required: true
      }
    },
    computed: {
      applicant: {
        get() {
          return this.value;
        },
        set(value) {
          this.v.$touch();
          this.$emit('input', value);
        }
      },
    },
    data() {
      return {
        //screenSize = $screen.width,
        showContactPerson: false,
        relationshipOptions: selectOptions.relationshipOptions,
        authorisationOptions: selectOptions.authorisationOptions,
        phoneTypeOptions: selectOptions.phoneTypeOptions,
        emailInfo: 'Wir benötigen Ihre E-Mail-Adresse, um Ihnen eine Eingangsbestätigung Ihrer '
          + 'Anmeldung, Statusänderungen Ihrer Anmeldung oder Aktualisierungsanfragen zu Ihren '
          + 'hinterlegten Daten zuzusenden. Falls Sie keine E-Mail-Adresse haben, fragen Sie bitte '
          + 'eine vertraute Person, ob diese bereit ist, Ihnen hierfür ihre E-Mail-Adresse zur '
          + 'Verfügung zu stellen. Andernfalls bitten wir Sie, alle 12 Monate Kontakt zur '
          + 'Abteilung Altenhilfe des Sozialamts der Stadt Konstanz aufzunehmen, damit Ihre '
          + 'Anmeldung weiterhin gelistet bleiben kann.'
      };
    },
    methods: {
      addPhoneNumber(isContactPerson) {
        if(isContactPerson) {
          this.applicant.contactPersonPhoneNumbers.push({ type: 'HOME', phone: '' })
        } else {
          this.applicant.phoneNumbers.push({ type: 'HOME', phone: '' });
        }
      },
      removePhoneNumber(isContactPerson, index) {
        if(isContactPerson) {
          this.applicant.contactPersonPhoneNumbers.splice(index, 1)
        } else {
          this.applicant.phoneNumbers.splice(index, 1);
        }
      },
      // SKH-246
      identicalPersonChanged(newValue) {
        if (newValue) {
          const initialApplicantState = Object.assign({}, initialApplicationState().applicant);
          delete initialApplicantState.identicalPerson;
          Object.assign(this.applicant, initialApplicantState);
        }
      },
      // SKH-246
      differentAddressChanged(newValue) {
        if (newValue) {
          this.applicant.postalCode = null;
          this.applicant.city = null;
          this.applicant.address = null;
        }
      },
      // SKH-246
      showContactPersonChanged(newValue) {
        if (newValue) {
          this.applicant.contactPersonFirstName = null;
          this.applicant.contactPersonLastName = null;
        }
      }
    },
    watch: {
      // SKH-129
      'applicant.authorisations': function (newValue) {
        const indexOfFull = newValue.indexOf('FULL');
        if (indexOfFull >= 0) {
          const lastIndex = newValue.length - 1;
          if (indexOfFull !== lastIndex) {
            this.applicant.authorisations = newValue.filter(elem => elem !== 'FULL');
          } else if (newValue.length > 1) {
            this.applicant.authorisations = ['FULL'];
          }
        }
      }
    },
    validations: {
      applicant: {
        firstName: {
          required:  requiredIf(function () {
            return !this.applicant.identicalPerson
          }),
          minLength: minLength(2)
        },
        lastName: {
          required: requiredIf(function () {
            return !this.applicant.identicalPerson
          }),
          minLength: minLength(2)
        },
        relationship: {
          required:  requiredIf(function () {
            return !this.applicant.identicalPerson
          })
        },
        contactPersonFirstName: {
          required: requiredIf(function () {
            return !this.applicant.identicalPerson && this.showContactPerson
          })
        },
        contactPersonLastName: {
          required: requiredIf(function () {
            return !this.applicant.identicalPerson && this.showContactPerson
          })
        },
        contactPersonPhoneNumbers: {
          $each: {
            type: {
              required: requiredIf(function() {
                return !this.applicant.identicalPerson && this.showContactPerson
              })
            },
            phone: {
              required: requiredIf(function() {
                return !this.applicant.identicalPerson && this.showContactPerson
              })
            }
          }
        },
        address: {
          required:  requiredIf(function () {
            return !this.applicant.identicalPerson && this.applicant.differentAddress
          }),
          minLength: minLength(4)
        },
        postalCode: {
          required:  requiredIf(function () {
            return !this.applicant.identicalPerson && this.applicant.differentAddress
          }),
          minLength: minLength(5),
          maxLength: maxLength(5)
        },
        city: {
          required: requiredIf(function () {
            return !this.applicant.identicalPerson && this.applicant.differentAddress
          }),
          minLength: minLength(2)
        },
        phoneNumbers: {
          $each: {
            type: { 
              required: requiredIf(function () {
                return !this.applicant.identicalPerson
              })
            },
            phone: {
              required: requiredIf(function () {
                return !this.applicant.identicalPerson
              })
            }
          }
        },
        email: {
          email
        },
        authorisedPerson: {
          required: requiredIf(function () {
            return !this.applicant.identicalPerson
          })
        },
        differentAddress: {
          required: requiredIf(function () {
            return !this.applicant.identicalPerson
          })
        },
        legalCare: {
          required: requiredIf(function () {
            return !this.applicant.identicalPerson
          })
        }
      }
    }
  };
</script>
