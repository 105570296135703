<template>
  <div id="app">
    <sui-container style="margin-top: 14px; padding-bottom: 0">
      <sui-grid>
        <sui-grid-column :width="5" style="border-top: 36px solid #e40521">

          <router-link to="/" @click.native="resetState()">
            <img src="../public/assets/img/logo-konstanz.png"
                 alt="Logo Konstanz - Die Stadt zum See">
          </router-link>
        </sui-grid-column>
        <sui-grid-column :width="11" style="border-top: 36px solid #e3e2e1">
        </sui-grid-column>
      </sui-grid>
    </sui-container>

    <portal-target name="semantic-ui-vue"/>

    <div id="content">
      <router-view/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'app',
    methods:{
      resetState(){
        this.$store.commit('resetState');
      }
    }
  }
</script>

<style>
  #content {
    margin-top: 45px;
  }

  a.disabled {
    cursor: not-allowed;
    color: grey;
    text-decoration: none;
  }
</style>