const selectOptionsMixin = {
  methods: {
    optionText(options, value) {
      if (Array.isArray(options)) {
        const match = options.find(elem => elem.value === value);
        if (match && match.hasOwnProperty('text')) {
          return match.text;
        }
      } else {
        // eslint-disable-next-line no-console
        console.error('options is not an Array:', options);
      }
      return '?';
    }
  }
};

export default selectOptionsMixin;
