<template>
  <p style="font-weight: bold">
    {{ address.firstName }}
    {{ address.lastName }}
    <br/>
    {{ address.address }}
    <br/>
    {{ address.postalCode }}
    {{ address.city }}
  </p>
</template>

<script>
  export default {
    props: {
      address: {
        type: Object,
        required: true
      }
    }
  }
</script>
