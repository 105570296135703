import mutations from './mutations';
import getters from './getters';
import { initialApplicationState } from '@/application'
import createPersistedState from 'vuex-persistedstate';

const state = {
  application: initialApplicationState(),
  passedApplicationId: null,
  passedBirthDate: null
};

export default {
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  state,
  getters,
  mutations
}
