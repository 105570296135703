const checkDateMixin = {
    methods: {
        isBetween(intToTest, lowerLimit, upperLimit) {
            var isValid = (intToTest >= lowerLimit && intToTest <= upperLimit) ? true : false;
                return isValid;
        },
        isValidDateFormat(stringToCheck, shouldBeFuture) {
            console.log("called mixin")
            var ints = []
            try {
                stringToCheck.split(".").forEach(element => {
                    ints.push(parseInt(element))
                });
            } catch (err) { 
                console.log("error")
                return false
            }

            if (ints.length != 3 ) {return false}

            if (this.isBetween(ints[0], 1, 31)) {
                if(this.isBetween(ints[1], 1, 12)) {
                    if(this.isBetween(ints[2], 1900, 3000 )) {
                        //isValid -> check for future or past if 'shouldBeFuture' is present
                        if(shouldBeFuture !== undefined) {
                            console.log("is Valid Format: checking date")
                            var result = (shouldBeFuture ? this.isFutureDate(ints[2], ints[1], ints[0]) : this.isPastDate(ints[2], ints[1], ints[0]))
                            return result
                        } else {
                            console.log("missing")
                            return true; // shouldBeFuture was not present = only format check
                        }
                    } else {return false}
                } else {return false}
            } else {return false}
        },
        isFutureDate(year, month, day) {
            var today = new Date()
            var toTest = new Date(year, month-1, day) // -1 because starts at 0
            if(today < toTest) {
                console.log("is Future date: ", toTest)
                return true
            } else {
                console.log("not Future date: ", toTest)
                return false
            } 
        },
        isPastDate(year, month, day) {
            var today = new Date()
            var toTest = new Date(year, month-1, day)
            if(today >= toTest) {
                console.log("is past date")
                return true
            } else {
                console.log("is not past date")
                return false
            } 
        }
    }
  };
  
  export default checkDateMixin;
  