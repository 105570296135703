<template>
  <sui-message error>
    <sui-message-content>
      <p>
        Ihre Anmeldung wird demnächst gelöscht. Bitte verlängern Sie Ihre
        Anmeldung, wenn Sie weiterhin Bedarf an einer Heimunterbrinung haben.
      </p>
    </sui-message-content>
  </sui-message>
</template>

<script>
export default {
  props: {
  },
};
</script>
