<template>
  <div>
    <h2 class="ui dividing header">Einrichtung</h2>

    <sui-grid style="margin-bottom: 1em">
      <sui-grid-column :width="14">
      <sui-table>
        <sui-table-header>
          <sui-table-row>
            <sui-table-header-cell></sui-table-header-cell>
            <sui-table-header-cell>Einrichtung</sui-table-header-cell>
            <sui-table-header-cell>Stadtteil</sui-table-header-cell>
            <sui-table-header-cell>Anschrift</sui-table-header-cell>
            <sui-table-header-cell></sui-table-header-cell>
          </sui-table-row>
        </sui-table-header>
        <sui-table-body>
          <sui-table-row v-for="(institutionOption, index) in availableInstitutionsOptions" :key="index">
            <sui-table-cell collapsing>
              <sui-checkbox
                :value="institutionOption"
                v-model="selectedInstitutions"
              />
            </sui-table-cell>
            <sui-table-cell>
              {{ institutionOption.name }}
            </sui-table-cell>
            <sui-table-cell>
              {{ institutionOption.district }}
            </sui-table-cell>
            <sui-table-cell>
              {{ institutionOption.address }}
            </sui-table-cell>
            <sui-table-cell>
              <a :href="institutionOption.url"
                 target="_blank" rel="noopener noreferrer">
                Weitere Informationen
                <i class="external grey icon"></i>
              </a>
            </sui-table-cell>
          </sui-table-row>
        </sui-table-body>
      </sui-table>
      <span v-if="$v.institutionSelection.institutions.$error" class="ui negative message">
        Bitte wählen Sie die gewünschten Einrichtungen aus.</span>
      </sui-grid-column>
    </sui-grid>

    <InstitutionsMap :institutionInfos="availableInstitutionsOptions" />

    <sui-form-fields>
      <sui-form-field :width="5" required
                      :class="{ error: $v.institutionSelection.roomSituation.$error }">
        <label>Doppelzimmer möglich</label>
        <sui-form-fields grouped>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="institutionSelection.roomSituation.DOUBLE_ROOM"
                     type="radio"
                     v-model="institutionSelection.roomSituation"
                     value="DOUBLE_ROOM"/>
              <label for="institutionSelection.roomSituation.DOUBLE_ROOM">Ja</label>
            </div>
          </div>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="institutionSelection.roomSituation.SINGLE_ROOM"
                     type="radio"
                     v-model="institutionSelection.roomSituation"
                     value="SINGLE_ROOM"/>
              <label for="institutionSelection.roomSituation.SINGLE_ROOM">Nein</label>
            </div>
          </div>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="institutionSelection.roomSituation.TEMPORARY_DOUBLE_ROOM"
                     type="radio"
                     v-model="institutionSelection.roomSituation"
                     value="TEMPORARY_DOUBLE_ROOM"/>
              <label for="institutionSelection.roomSituation.TEMPORARY_DOUBLE_ROOM">Interesse an einem Einzelzimmer, vorübergehend ist ein Einzug in ein Doppelzimmer jedoch möglich</label>
            </div>
          </div>
        </sui-form-fields>
        <small v-if="$v.institutionSelection.roomSituation.$error">
          Bitte geben Sie an, ob ein Doppelzimmer möglich ist.
        </small>
      </sui-form-field>
    </sui-form-fields>

    <sui-form-fields>
      <sui-form-field :width="9">
        <label for="institutionSelection.comments">Hinweise und Wünsche an die Einrichtung</label>
        <textarea
          id="institutionSelection.comments"
          v-model="institutionSelection.comments"
          rows="2"></textarea>
      </sui-form-field>
    </sui-form-fields>
  </div>
</template>

<script>
  import { required, minLength } from "vuelidate/lib/validators";
  import InstitutionsMap from "@/components/application/sections/InstitutionsMap";

  export default {
    name: 'InstitutionSection',
    components: {
      InstitutionsMap
    },
    props: {
      value: {
        type: Object,
        required: true
      },
      institutionOptions: {
        type: Array,
        required: true
      }
    },
    computed: {
      institutionSelection: {
        get() {
          return this.value;
        },
        set(value) {
          this.v.$touch();

          this.$emit('input', value);
        }
      },
    },
    mounted() {
      this.availableInstitutionsOptions = this.institutionOptions.sort((a, b) => {
        return (a.district > b.district) ? 1 : (a.district === b.district) ? ((a.name > b.name) ? 1 : -1) : -1;
      });

      const selectedGroupIds = this.value.institutions.map(option => option.groupId);
      this.selectedInstitutions = this.availableInstitutionsOptions.filter(institution => selectedGroupIds.includes(institution.groupId));
    },
    data() {
      return {
        error: null,
        availableInstitutionsOptions:[],
        selectedInstitutions: [],
      };
    },
    watch: {

      selectedInstitutions: function (newValue) {

        this.value.institutions = newValue.map(institution => {return {
          groupId: institution.groupId
        }});
        this.$emit('input', this.value);
      }
    },
    validations: {
      institutionSelection: {
        institutions: {
          required,
          minLength: minLength(1)
        },
        roomSituation: {
          required
        }
      }
    }
  };
</script>
