import axios from 'axios';

let _serviceUrlPrefix = '/heimplatz-bpm/rest';

class RetirementHomeService {
  static get serviceUrlPrefix() {
    return _serviceUrlPrefix;
  }

  static retrieveApplicationStatus(applicationId, birthDate) {
    return axios.post(`${RetirementHomeService.serviceUrlPrefix}/status`, {
      applicationId, birthDate
    });
  }

  static retrieveApplication(applicationId, birthDate) {
    return axios.post(`${RetirementHomeService.serviceUrlPrefix}/applicant/application`, {
      applicationId, birthDate
    });
  }

  static deleteApplication(applicationId, birthDate) {
    return axios.delete(`${RetirementHomeService.serviceUrlPrefix}/applicant/application`, {
      data: {
        applicationId, birthDate
      }
    });
  }

  static updateApplication(data) {
    return axios.post(`${RetirementHomeService.serviceUrlPrefix}/applicant/application/update`, {
      ...data
    });
  }

  static createApplication(data) {
    return axios.post(`${RetirementHomeService.serviceUrlPrefix}/applicant/application/create`, {
      ...data
    });
  }

  static uploadFile(applicationId, formData) {
    return axios.post(`${RetirementHomeService.serviceUrlPrefix}/files/application/${applicationId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  static retrieveSummaryPdf(){
    return axios.get(`${RetirementHomeService.serviceUrlPrefix}/summary/pdf`);
  }

  static getRetirementHomeOptions() {
    return axios.get(`${RetirementHomeService.serviceUrlPrefix}/options/retirement-homes`);
  }

  static getNursingServicesOptions() {
    return axios.get(`${RetirementHomeService.serviceUrlPrefix}/nursing-services`);
  }

  static getDoctorsOptions() {
    return axios.get(`${RetirementHomeService.serviceUrlPrefix}/doctors`);
  }

  static renewApplication(applicationId, birthDate) {
    return axios.post(`${RetirementHomeService.serviceUrlPrefix}/applicant/application/renew`, {
      applicationId, birthDate
    });
  }

}

export default RetirementHomeService;
