import Vue from 'vue';
import Router from 'vue-router';

import HomePage from '@/HomePage';
import ApplicationForm from '@/components/application/ApplicationForm';
import LoadExistingApplicationPage from '@/components/LoadExistingApplicationPage';
import DashboardPage from '@/components/dashboard/DashboardPage';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomePage,
      props: true
    },
    {
      path: '/loadExistingApplication',
      component: LoadExistingApplicationPage
    },
    // SKH-159
    {
      path: '/application/status',
      redirect: (to) => ({
        path: '/dashboard',
        query: {
          aid: to.query.aid,
          bd: to.query.bd,
        }
      })
    },
    {
      path: '/application',
      name: 'application',
      component: ApplicationForm,
      props: true
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: DashboardPage,
      props: (route) => route.query.aid ? { applicationId: route.query.aid, birthDate: route.query.bd } : route.params
    },
  ]
});
