<template>
  <div>
    <h3>Betroffene Person</h3>
    <sui-grid divided="vertically" stackable>
      <sui-grid-row :columns="4">
        <sui-grid-column>
          <SummaryAddress :address="application.patient"/>
        </sui-grid-column>
        <sui-grid-column>
          <div class="ui divided relaxed list">
            <div class="item">
              <div class="content">
                <div class="header">Geschlecht</div>
                <div class="description">
                  {{ optionText(selectOptions.genderOptions, application.patient.gender) }}
                </div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">Geburtssdatum</div>
                <div class="description">
                  {{ application.patient.dateOfBirth | displayDate}}
                </div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">Staatsangehörigkeit</div>
                <div class="description">
                  {{ optionText(selectOptions.citizenshipOptions,
                       application.patient.citizenship) }}
                </div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">Konfession</div>
                <div class="description">
                  {{ optionText(selectOptions.denominationOptions,
                       application.patient.denomination) }}
                </div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">Direkte Kontaktaufnahme zur betroffenen Person erwünscht</div>
                <div class="description">
                  <span v-if="application.patient.contactDesired">Ja</span>
                  <span v-else>Nein</span>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">Telefon</div>
                <div class="description">
                  <span v-if="application.patient.phone">{{ application.patient.phone }}</span>
                  <span v-else>Keine Angabe</span>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">E-Mail-Adresse</div>
                <div class="description">
                  <span v-if="application.patient.email">{{ application.patient.email }}</span>
                  <span v-else>Keine Angabe</span>
                </div>
              </div>
            </div>
          </div>
        </sui-grid-column>

        <sui-grid-column>
          <div class="ui divided relaxed list">
            <div class="item">
              <div class="content">
                <div class="header">Geschützte Einrichtung erforderlich</div>
                <div class="description">
                  <span v-if="application.patient.runTendency">Ja</span>
                  <span v-else>Nein</span>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">An Demenz erkrankt</div>
                <div class="description">
                  <span v-if="application.patient.dementia">Ja</span>
                  <span v-else>Nein</span>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">Raucher</div>
                <div class="description">
                  <span v-if="application.patient.smoker">Ja</span>
                  <span v-else>Nein</span>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">Aktuell ansteckende Krankheit/Infektion</div>
                <div class="description">
                  <span v-if="application.patient.contagiousDiseaseInfection">Ja</span>
                  <span v-else>Nein</span>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">Schwere Bewegungseinschränkung</div>
                <div class="description">
                  <span v-if="application.patient.restrictionMovement">Ja</span>
                  <span v-else>Nein</span>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">Notwendigkeit der Beatmung</div>
                <div class="description">
                  <span v-if="application.patient.ventilationNecessary">Ja</span>
                  <span v-else>Nein</span>
                </div>
              </div>
            </div>
          </div>
        </sui-grid-column>

        <sui-grid-column>
          <div class="ui divided relaxed list">
            <div class="item">
              <div class="content">
                <div class="header">Anmeldung für</div>
                <div class="description">
                  {{ careType() }}
                </div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">Pflegegrad-Status</div>
                <div class="description">
                  {{ optionText(selectOptions.careLevelStatusOptions,
                       application.patient.careLevelStatus) }}
                </div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">Aktuell bescheinigter Pflegegrad</div>
                <div class="description">
                  {{ optionText(selectOptions.careLevelOptions, application.patient.careLevel) }}
                </div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">Einstufung seit</div>
                <div class="description" v-if="application.patient.careLevelSince">
                  {{ application.patient.careLevelSince | displayDate}}
                </div>
                <div v-else>
                  keine Angabe
                </div>
              </div>
            </div>
            <div class="item" v-if="application.patient.careType != 'PROVISIONAL'">
              <div class="content">
                <div class="header">Aufnahme</div>
                <div class="description">
                  <span v-if="application.patient.placementRequiredAsap">
                    Schnellstmögliche Aufnahme erforderlich<br/>
                    Grund: {{ optionText(selectOptions.placementAsapReasons, application.patient.placementRequiredAsapReason) }}
                    Ergänzung: {{ application.patient.placementRequiredAsapAddition }}
                  </span>
                  <span v-else>
                    Wunschtermin
                    {{ application.patient.desiredPlacementDate | displayDate}}
                  </span>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">Derzeitige Versorgung</div>
                <div class="description">
                  {{ optionText(selectOptions.caregiverOptions,
                  application.patient.currentCaregiver) }}
                </div>
              </div>
            </div>
            <div class="item" v-if="application.patient.currentCaregiver !== 'RELATIVES'">
              <div class="content">
                <div class="header">Einrichtung, Dienst</div>
                <div class="description">
                  {{ textFromArray(application.patient.currentCareFacility) }}
                </div>
              </div>
            </div>
          </div>
        </sui-grid-column>
      </sui-grid-row>
    </sui-grid>

    <div class="ui divider"></div>

    <h3>Anmeldende Person</h3>
    <sui-grid stackable>
      <sui-grid-row v-if="!application.applicant.lastName" :columns="1">
        <sui-grid-column>
          <p>Die anmeldende Person ist identisch mit der betroffenen Person.</p>
        </sui-grid-column>
      </sui-grid-row>
      <sui-grid-row v-else :columns="4">
        <sui-grid-column>
          <SummaryAddress :address="application.applicant"/>
        </sui-grid-column>
        <sui-grid-column>
          <div class="ui divided relaxed list">
            <div class="item" v-if="application.applicant.contactPersonFirstName">
              <div class="content">
                <div class="header">Kontaktperson</div>
                <div class="description">
                  {{ application.applicant.contactPersonFirstName }}
                  {{ application.applicant.contactPersonLastName }}
              </div>
            </div>
            <div class="item" v-if="application.applicant.contactPersonFirstName">
              <div class="content">
                  <div class="header">Telefon der Kontaktperson</div>
                    <div class="description">
                      <div v-for="(contactPhone, index) in application.applicant.contactPersonPhoneNumbers" :key="index">
                            {{ optionText(selectOptions.phoneTypeOptions, contactPhone.type) }}:
                            {{ contactPhone.phone }}
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div clas="content">
                <div class="header">Hinweise zur Erreichbarkeit der Kontaktperson</div>
                <div class= "description">
                  {{application.applicant.contactPersonPhoneRemarkText}}
                </div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">Telefon</div>
                <div class="description">
                  <ul style="list-style: none; margin: 0; padding: 0">
                    <li v-for="(phoneNumber, index) in application.applicant.phoneNumbers"
                        v-bind:key="index">
                      {{ optionText(selectOptions.phoneTypeOptions, phoneNumber.type) }}:
                      {{ phoneNumber.phone }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div v-if="application.applicant.phoneRemarkText" class="item">
              <div class="content">
                <div class="header">Hinweis zur Erreichbarkeit</div>
                <div class="description">{{ application.applicant.phoneRemarkText }}</div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">E-Mail</div>
                <div class="description">{{ application.applicant.email }}</div>
              </div>
            </div>
          </div>
        </sui-grid-column>
        <sui-grid-column>
          <div class="ui divided relaxed list">
            <div class="item">
              <div class="content">
                <div class="header">Verhältnis zur betroffenen Person</div>
                <div class="description">
                  {{ optionText(selectOptions.relationshipOptions,
                       application.applicant.relationship) }}
                </div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">Bevollmächtigte Person oder gesetzliche Betreuung</div>
                <div class="description">
                  <span v-if="application.applicant.authorisedPerson">Ja</span>
                  <span v-else>Nein</span>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">Gesetzliche Betreuung wurde angeregt</div>
                <div class="description">
                  <span v-if="application.applicant.legalCare">Ja</span>
                  <span v-else>Nein</span>
                </div>
              </div>
            </div>
          </div>
        </sui-grid-column>
        <sui-grid-column>
          <div class="ui divided relaxed list">
            <div id="applicant.authorisations" class="item">
              <div class="content">
                <div class="header">Wirkungskreis der Betreuung/Vollmacht</div>
                <div class="description" v-if="authorisations">
                  {{ authorisations }}
                </div>
                <div class="description" v-else>
                  keine Angabe
                </div>
              </div>
            </div>
          </div>
        </sui-grid-column>
      </sui-grid-row>
    </sui-grid>

    <div class="ui divider"></div>

    <sui-grid stackable>
      <sui-grid-row :columns="4">
        <sui-grid-column>
          <h3>Hausarzt</h3>
          <p>
            {{ application.doctor.name }}
            <br/>
            {{ application.doctor.address }}
            <br/>
            {{ application.doctor.postalCode }}
            {{ application.doctor.city }}
            <br/>
          </p>
            Tel. {{ application.doctor.phone }}
            <p v-if="application.doctor.medicalCare === 'YES'">
              <i class="check circle icon green"/>
              Übernahme der ärztlichen Betreuung
            </p>
            <p v-if="application.doctor.medicalCare === 'NO'">
              <i class="times circle icon red"/>
              Keine Übernahme der ärztlichen Betreuung
            </p>
            <p v-if="application.doctor.medicalCare === 'NOT_SPECIFIED'">
              <i class="minus circle icon grey"/>
              Keine Angabe über die Übernahme der ärztlichen Betreuung
            </p>
        </sui-grid-column>
        <sui-grid-column>
          <h3>Finanzierung</h3>
          <div class="ui divided relaxed list">
            <div class="item">
              <div class="content">
                <div class="header">Kranken- bzw. Pflegeversicherung</div>
                <div class="description">{{ application.financing.insurance }}</div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">Unterstützung durch die Sozialhilfe</div>
                <div class="description">
                  {{ optionText(selectOptions.socialWelfareSupportTypeOptions,
                       application.financing.socialWelfareSupportType) }}
                </div>
              </div>
            </div>
            <div class="item"
                 v-if="application.financing.socialWelfareSupportType !== 'NONE'">
              <div class="content">
                <div class="header">Zuständiges Sozialamt</div>
                <div class="description">{{ application.financing.socialWelfareOffice }}</div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">Selbstzahler</div>
                <div class="description">
                  <span v-if="application.financing.selfPayer">Ja</span>
                  <span v-else>Nein</span>
                </div>
              </div>
            </div>
            <div class="item"
                 v-if="application.financing.comments">
              <div class="content">
                <div class="header">Hinweise zur Finanzierung</div>
                <div class="description">{{ application.financing.comments }}</div>
              </div>
            </div>
          </div>
        </sui-grid-column>
        <sui-grid-column>
          <h3>Einrichtung</h3>
          <div class="ui divided relaxed list">
            <div class="item">
              <div class="content">
                <div class="header">Gewünschte Einrichtungen</div>
                <div class="description">
                  <ul id="institutionNames" style="list-style: none; margin: 0; padding: 0">
                    <li v-for="institution in application.institutionSelection.institutions"
                        :key="institution">
                      {{ getInstitutionNameForDisplay(institution.groupId)  }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="content">
                <div class="header">Doppelzimmer möglich</div>
                <div class="description">
                  <span>{{roomSituation()}}</span>
                </div>
              </div>
            </div>
            <div class="item"
                 v-if="application.institutionSelection.comments">
              <div class="content">
                <div class="header">Hinweis zur Einrichtung</div>
                <div class="description">{{ application.institutionSelection.comments }}</div>
              </div>
            </div>
          </div>
        </sui-grid-column>
        <sui-grid-column>
          <h3>Dokumente</h3>
          <div v-if="application.documents.files.length"
               class="ui divided relaxed list">
            <div class="item"
                 v-for="file in application.documents.files"
                 :key="file.name">
              <i class="grey file icon"></i>
              {{ file.name }}
            </div>
          </div>
          <p v-else>Es wurden keine Dokumente zu dieser Anmeldung hinzugefügt.</p>
        </sui-grid-column>
      </sui-grid-row>
    </sui-grid>
  </div>
</template>

<script>
  import SummaryAddress from '@/components/SummaryAddress';
  import selectOptions from '@/assets/selectOptions.json';
  import selectOptionsMixin from '@/mixins/selectOptionsMixin';
  import moment from 'moment';
  export default {
    components: {
      SummaryAddress
    },
    mixins: [
      selectOptionsMixin
    ],
    props: {
      application: {
        type: Object,
        required: true
      },
      institutionOptions: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        selectOptions
      }
    },
    methods: {
      textFromArray(array){
        let textFromArray = "";
        for(let i = 0; i < array.length; ++i){
          textFromArray += array[i] + "\n";
        }
        return textFromArray;
      },
      careType() {
        const careType = this.application.patient.careType;
        return careType === 'PERMANENT'
          ? 'Dauerpflege'
          : careType === 'SHORT_TERM'
            ? 'Kurzzeitpflege'
            : 'Vorsorgliche Anmeldung'
      },
      roomSituation(){
        const roomSituation = this.application.institutionSelection.roomSituation;
        return roomSituation === 'SINGLE_ROOM'
          ? 'Nein'
          : roomSituation === 'DOUBLE_ROOM'
            ? 'Ja'
            : 'Interesse an einem Einzelzimmer, vorübergehend ist ein Einzug in ein Doppelzimmer jedoch möglich'
      },
      getInstitutionNameForDisplay(groupId){
        let res = this.institutionOptions.find((elem) => elem.groupId == groupId);
        if(res != null && res.name != null) return res.name;
        return groupId; 
      }
    },
    computed: {
      authorisations: function () {
        const authorisations = this.application.applicant.authorisations;
        if (!authorisations) {
          return '';
        }
        return authorisations
          .map(value => this.optionText(selectOptions.authorisationOptions, value))
          .join(', ');
      }
    },
    filters: {
      displayDate: function (date) {
        if(!date) return;
        return moment(date).format("DD.MM.YYYY");
      }
    }
  }
</script>
