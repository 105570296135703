import { initialApplicationState } from '@/application'

export default {
  updateApplication(state, application) {
    state.application = application;
  },
  resetState (state) {
    state.application = initialApplicationState();
    state.passedApplicationId = null;
    state.passedBirthDate = null;
  },
}