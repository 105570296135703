export const initialApplicationState = () => ({
  patient: {
    firstName: null,
    lastName: null,
    dateOfBirth: null,
    address: null,
    postalCode: null,
    city: null,
    contactDesired: null,
    phone: null,
    email: null,
    denomination: null,
    dementia: null,
    smoker: null,
    contagiousDiseaseInfection: null,
    restrictionMovement: null,
    ventilationNecessary: null,
    citizenship: null,
    careLevelStatus: null,
    careLevel: null,
    careLevelSince: null,
    careType: null,
    applicationType: null,
    shortTermCare: null,
    runTendency: null,
    desiredPlacementDate: null,
    placementRequiredAsap: false,
    placementRequiredAsapReason: null,
    placementRequiredAsapAddition: null,
    currentCaregiver: null,
    currentCareFacility: null
  },
  applicant: {
    firstName: null,
    lastName: null,
    address: null,
    postalCode: null,
    city: null,
    authorisedPerson: null,
    legalCare: null,
    phoneRemarkText: null,
    phoneNumbers: [
      {
        type: 'HOME',
        phone: ''
      }
    ],
    email: null,
    relationship: null,
    contactPersonFirstName: null,
    contactPersonLastName: null,
    contactPersonPhoneNumbers: [ 
      {
        type: 'MOBILE',
        phone: ''
      }
    ],
    contactPersonPhoneRemarkText: null,
    authorisations: [],
    identicalPerson: true,
    differentAddress: false
  },
  doctor: {
    name: null,
    address: null,
    postalCode: null,
    city: null,
    phone: null,
    medicalCare: undefined
  },
  financing: {
    insurance: null,
    socialWelfareSupportType: null,
    socialWelfareOffice: null,
    selfPayer: false,
    comments: null
  },
  institutionSelection: {
    institutions: [],
    comments: null
  },
  documents: {
    files: [],
    documents: [],
  }
});
