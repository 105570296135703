<template>
  <div>
    <h2 class="ui dividing header">Hausarzt</h2>

    <sui-form-fields>
      <sui-form-field :width="11" required :class="{ error: $v.doctor.name.$error }">
        <label>Name
        <sui-popup>
          <sui-popup-content>
            <p>Sie können einen Hausarzt aus dem Dropdown auswählen oder selbst eine Eingabe tätigen und mit Enter bestätigen.</p>
          </sui-popup-content>
          <i slot="trigger"
              class="small circular grey inverted question icon"
              style="margin-left: 5px"></i>
        </sui-popup>
        </label>
        <sui-dropdown
          fluid
          search
          multiple
          selection
          allow-additions
          :options="doctorOptions"
          placeholder="Name"
          v-model="selectedDoctorNames"
        />
        <small v-if="$v.doctor.name.$error">Bitte geben Sie den Namen ein.</small>
      </sui-form-field>
    </sui-form-fields>

    <sui-form-fields>
      <sui-form-field :width="2" required :class="{ error: $v.doctor.postalCode.$error }">
        <label>PLZ</label>
        <sui-input placeholder="PLZ" v-model="doctor.postalCode" />
        <small v-if="$v.doctor.postalCode.$error">Bitte geben Sie die PLZ ein.</small>
      </sui-form-field>
      <sui-form-field :width="4" required :class="{ error: $v.doctor.city.$error }">
        <label>Ort</label>
        <sui-input placeholder="Ort" v-model="doctor.city" />
        <small v-if="$v.doctor.city.$error">Bitte geben Sie den Ort ein.</small>
      </sui-form-field>
      <sui-form-field :width="5" required :class="{ error: $v.doctor.address.$error }">
        <label>Straße und Hausnummer</label>
        <sui-input placeholder="Straße und Hausnummer" v-model="doctor.address" />
        <small v-if="$v.doctor.address.$error">Bitte geben Sie die Straße und Hausnummer an.</small>
      </sui-form-field>
    </sui-form-fields>

    <sui-form-fields>
      <sui-form-field :width="5" required :class="{ error: $v.doctor.phone.$error }">
        <label>Telefon</label>
        <sui-input type="tel" placeholder="Telefon" v-model="doctor.phone" />
        <small v-if="$v.doctor.phone.$error">Bitte geben Sie die Telefonnummer ein.</small>
      </sui-form-field>
    </sui-form-fields>

    <sui-form-field required :class="{ error: $v.doctor.medicalCare.$error }" style="margin-top:10px">
      <label>
        Übernahme der ärztlichen Betreuung
        <sui-popup content="Kann der Arzt/die Ärztin die ärztliche Betreuung im Pflegeheim übernehmen?"
                       wide="very"
                       position="center top">
          <i slot="trigger"
            class="small circular grey inverted question icon"
            style="margin-left: 5px"></i>
        </sui-popup>
      </label>
      <sui-form-fields inline>
        <div class="field">
          <div class="ui radio checkbox">
            <input id="medicalCareTrue" type="radio" v-model="doctor.medicalCare" value="YES" />
            <label for="medicalCareTrue">Ja</label>
          </div>
        </div>
        <div class="field">
          <div class="ui radio checkbox">
            <input id="medicalCareFalse" type="radio" v-model="doctor.medicalCare" value="NO" />
            <label for="medicalCareFalse">Nein</label>
          </div>
        </div>
        <div class="field">
          <div class="ui radio checkbox">
            <input id="medicalCareNull" type="radio" v-model="doctor.medicalCare" value="NOT_SPECIFIED" />
            <label for="medicalCareNull">Keine Angabe</label>
          </div>
        </div>
      </sui-form-fields>
      <div v-if="$v.doctor.medicalCare.$dirty">
        <small
          v-if="$v.doctor.medicalCare.$error"
        >Bitte geben Sie an, ob der angegebene Hausarzt die ärztliche Pflege übernehmen kann.</small>
      </div>
    </sui-form-field>
  </div>
</template>

<script>
import RetirementHomeService from "@/services/retirementHomeService";
import { required } from "vuelidate/lib/validators";
import { notUndefined } from "@/validators";

export default {
  name: "DoctorSection",
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      availableDoctors: []
    };
  },
  computed: {
    doctor: {
      get() {
        return this.value;
      },
      set(value) {
        this.v.$touch();
        this.$emit("input", value);
      }
    },
    doctorOptions: function() {
      return this.availableDoctors.map(doctor => {
        return { text: doctor.name, value: doctor.name };
      });
    },
    selectedDoctorNames: {
      get() {
        if (this.doctor.name && 0 !== this.doctor.name) {
          return [this.doctor.name];
        }
        return [];
      },
      set(value) {
        // possible user actions
        // -> form is empty
        //    -> select from dropdown (1)
        //    -> enter custom name (2)
        // -> form is filled from dropdown
        //    -> remove doctor (3)
        //    -> select new doctor from dopdown (4)
        //    -> enter custom name (5)
        // -> form is filled with custom name
        //    -> remove custom name (6)
        //    -> select new doctor from dropdown (7)
        //    -> enter custom name (8)
        let selectedDoctor = value.length === 2 ? value[1] : value[0];
        let isDropdownDoctor = this.availableDoctors.filter(
          doctor => doctor.name === selectedDoctor
        )[0];
        
        if(isDropdownDoctor){
          this.doctor.name = isDropdownDoctor.name;
          this.doctor.address = isDropdownDoctor.streetAndHouseNumber;
          this.doctor.postalCode = isDropdownDoctor.zipCode;
          this.doctor.city = isDropdownDoctor.city;
          this.doctor.phone = isDropdownDoctor.phoneNumber;
        }else{
          this.doctor.name = selectedDoctor;
            this.doctor.address = "";
            this.doctor.postalCode = "";
            this.doctor.city = "";
            this.doctor.phone = "";
        }
      }
    }
  },
  mounted() {
    RetirementHomeService.getDoctorsOptions().then(result => {
      this.availableDoctors = result.data;
      if (this.doctor.name) {
        this.selectedDoctorName = [this.doctor.name];
      }
    });
    switch(this.doctor.medicalCare){
      case true:
        this.doctor.medicalCare = 'YES';
        break;
      case false:
        this.doctor.medicalCare = 'NO';
        break;
      case null:
        this.doctor.medicalCare = 'NOT_SPECIFIED';
        break;
      default:
        break;
    }
  },
  validations: {
    doctor: {
      name: {
        required
      },
      address: {
        required
      },
      postalCode: {
        required
      },
      city: {
        required
      },
      phone: {
        required
      },
      medicalCare: {
        required: notUndefined
      }
    }
  }
};
</script>
