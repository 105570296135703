<template>
  <form class="ui form"
        @submit.prevent="submit">
    <sui-container>
      <sui-form-field :class="{ error: $v.applicationId.$error }">
        <label>Chiffre-Nr.</label>
        <sui-input
          type="text"
          name="applicationId"
          v-model="applicationId"
          placeholder="Chiffre"/>
      </sui-form-field>

      <sui-form-field :class="{ error: $v.patientDob.$error }">
        <label>Geburtsdatum der betroffenen Person</label>
        <sui-input
          type="text"
          name="patientDob"
          v-model="patientDob"
          placeholder="TT.MM.YYY"/>
      </sui-form-field>
    </sui-container>

    <sui-container>
      <sui-button type="submit" primary>Weiter</sui-button>
    </sui-container>

    <div v-if="$v.$error" class="ui list">
      <div v-if="$v.applicationId.$error" class="error item">
        Bitte geben Sie eine gültige Chiffre ein.
      </div>
      <div v-if="$v.patientDob.$error" class="error item">
        Bitte geben Sie ein gültiges Geburtsdatum ein.
      </div>
    </div>
  </form>
</template>

<script>
  import { required, helpers } from "vuelidate/lib/validators";
  import checkDateMixin from '@/mixins/checkDateMixin';

  const applicationIdRegex = helpers.regex('applicationIdRegex', /^[A-Z0-9]{6}$/);

  function checkPastDateFormat(value) { return this.isValidDateFormat(value, false) }

  export default {
    mixins: [
      checkDateMixin
    ],
    data() {
      return {
        applicationId: '',
        patientDob: ''
      }
    },
    methods: {
      submit() {
        this.$v.$touch();

        if (!this.$v.$invalid) {
          this.$emit('submitApplicationId', {
            applicationId: this.applicationId,
            patientDob: this.patientDob
          })
        }
      }
    },
    validations: {
      applicationId: {
        required,
        applicationIdRegex
      },
      patientDob: {
        required,
        checkPastDateFormat
      }
    }
  }
</script>
