<template>
  <div>
    <h2 class="ui header dividing">Betroffene Person</h2>

    <sui-form-fields>
      <sui-form-field :width="4" required
                      :class="{ error: $v.patient.firstName.$error }">
        <label>Vorname</label>
        <sui-input
          placeholder="Vorname"
          v-model="patient.firstName"/>
        <small v-if="$v.patient.firstName.$error">Bitte geben Sie den Vornamen ein.</small>
      </sui-form-field>
      <sui-form-field :width="4" required
                      :class="{ error: $v.patient.lastName.$error }">
        <label>Nachname</label>
        <sui-input
          placeholder="Nachname"
          v-model="patient.lastName"/>
        <small v-if="$v.patient.lastName.$error">Bitte geben Sie den Nachnamen ein.</small>
      </sui-form-field>
      <sui-form-field :width="3" required
                      :class="{ error: $v.patient.gender.$error }">
        <label>Geschlecht</label>
        <sui-dropdown
          fluid
          search
          :options="genderOptions"
          placeholder="Bitte auswählen"
          selection
          v-model="patient.gender"/>
        <small v-if="$v.patient.gender.$error">Bitte wählen Sie das Geschlecht aus.</small>
      </sui-form-field>
      <sui-form-field :width="3" required
                      :class="{ error: $v.patient.dateOfBirth.$error }">
        <label>Geburtsdatum</label>
        <sui-input
          placeholder="TT.MM.JJJJ"
          type="text"
          v-model="dateOfBirth"
          @input="value => storeFormattedDate('dateOfBirth', value)"/>
        <small v-if="$v.patient.dateOfBirth.$error">Bitte geben Sie ein gültiges, vergangenes Geburtsdatum ein.</small>
      </sui-form-field>
    </sui-form-fields>

    <sui-form-fields>
      <sui-form-field :width="2" required
                      :class="{ error: $v.patient.postalCode.$error }">
        <label>PLZ</label>
        <sui-input
          placeholder="PLZ"
          v-model="patient.postalCode"/>
        <small v-if="$v.patient.postalCode.$error">Bitte geben Sie die PLZ ein.</small>
      </sui-form-field>
      <sui-form-field :width="4" required
                      :class="{ error: $v.patient.city.$error }">
        <label>Ort</label>
        <sui-input
          placeholder="Ort"
          v-model="patient.city"/>
        <small v-if="$v.patient.city.$error">Bitte geben Sie den Ort ein.</small>
      </sui-form-field>
      <sui-form-field :width="5" required
                      :class="{ error: $v.patient.address.$error }">
        <label>Straße und Hausnummer</label>
        <sui-input
          placeholder="Straße und Hausnummer"
          v-model="patient.address"/>
        <small v-if="$v.patient.address.$error">Bitte geben Sie Ihre Straße und Hausnummer an.</small>
      </sui-form-field>
      <sui-form-field :width="5"
                      required
                      :class="{ error: $v.patient.contactDesired.$error }">
        <label>Direkte Kontaktaufnahme zur betroffenen Person erwünscht</label>
        <sui-popup :content="contactDesiredInfo"
                     size="large" wide="very" 
                     position="left center">
        <sui-form-fields inline slot="trigger">
          <div class="field">
            <div class="ui radio checkbox">
              <input id="patient.contactDesired.true"
                     type="radio"
                     v-model="patient.contactDesired"
                     :value="true"/>
              <label for="patient.contactDesired.true">Ja</label>
            </div>
          </div>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="patient.contactDesired.false"
                     type="radio"
                     v-model="patient.contactDesired"
                     :value="false"/>
              <label for="patient.contactDesired.false">Nein</label>
            </div>
          </div>
        </sui-form-fields>
        </sui-popup>
        <small v-if="$v.patient.contactDesired.$error">
          Bitte geben Sie an, ob eine direkte Kontaktaufnahme erwünscht ist.
        </small>
      </sui-form-field>
    </sui-form-fields>

    <sui-form-fields>
      <sui-form-field :width="5"
                      :required="isContactDesired()" 
                      :class="{ error: $v.patient.phone.$error }">
        <label>Telefon</label>
        <sui-input
          type="tel"
          placeholder="Telefon"
          v-model="patient.phone"/>
        <small v-if="$v.patient.phone.$error">
          Bitte geben Sie die Telefonnummer ein.
        </small>
      </sui-form-field>
      <sui-form-field :width="6">
        <label>E-Mail</label>
        <sui-input
          placeholder="E-Mail"
          v-model="patient.email"/>
      </sui-form-field>
    </sui-form-fields>

    <sui-form-fields>
      <sui-form-field :width="5" required
                      :class="{ error: $v.patient.citizenship.$error }">
        <label>Staatsangehörigkeit</label>
        <sui-dropdown
          fluid
          search
          :options="citizenshipOptions"
          placeholder="Bitte auswählen"
          selection
          v-model="patient.citizenship"/>
        <small v-if="$v.patient.citizenship.$error">Bitte wählen Sie die Staatsangehörigkeit aus.</small>
      </sui-form-field>
      <sui-form-field :width="6">
        <label>Konfession</label>
        <sui-dropdown
          fluid
          search
          :options="denominationOptions"
          placeholder="Bitte auswählen"
          selection
          v-model="patient.denomination"/>
      </sui-form-field>
    </sui-form-fields>

      <div v-if="$screen.width <= 800">
        <div class="ui hidden divider"></div>
      </div>

    <sui-form-fields>
      <sui-form-field :width="4" required
                      :class="{ error: $v.patient.runTendency.$error }">
        <label>Geschützte Einrichtung erforderlich
          <sui-popup :content="runTendencyInfo"
                     position="center top" wide="very">
            <i slot="trigger"
               class="small circular grey inverted question icon"></i>
          </sui-popup>
        </label>
        <sui-form-fields grouped>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="patient.runTendency.true"
                     type="radio"
                     v-model="patient.runTendency"
                     :value="true"/>
              <label for="patient.runTendency.true">Ja</label>
            </div>
          </div>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="patient.runTendency.false"
                     type="radio"
                     v-model="patient.runTendency"
                     :value="false"/>
              <label for="patient.runTendency.false">Nein</label>
            </div>
          </div>
          <div v-if="$v.patient.runTendency.$dirty">
            <small v-if="$v.patient.runTendency.$error">
              Bitte geben Sie an, ob für die betroffene Person eine geschützte Einrichtung
              erforderlich ist.
            </small>
          </div>
        </sui-form-fields>
      </sui-form-field>
      <sui-form-field :width="3" required
                      :class="{ error: $v.patient.dementia.$error }">
        <label>An Demenz erkrankt</label>
        <sui-form-fields grouped>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="patient.dementia.true"
                     type="radio"
                     v-model="patient.dementia"
                     :value="true"/>
              <label for="patient.dementia.true">Ja</label>
            </div>
          </div>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="patient.dementia.false"
                     type="radio"
                     v-model="patient.dementia"
                     :value="false"/>
              <label for="patient.dementia.false">Nein</label>
            </div>
          </div>
          <div v-if="$v.patient.dementia.$dirty">
            <small v-if="$v.patient.dementia.$error">
              Bitte geben Sie an, ob die betroffene Person unter Demenz leidet.
            </small>
          </div>
        </sui-form-fields>
      </sui-form-field>
      <sui-form-field :width="2" required
                      :class="{ error: $v.patient.smoker.$error }">
        <label>Raucher</label>
        <sui-form-fields grouped>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="patient.smoker.true"
                     type="radio"
                     v-model="patient.smoker"
                     :value="true"/>
              <label for="patient.smoker.true">Ja</label>
            </div>
          </div>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="patient.smoker.false"
                     type="radio"
                     v-model="patient.smoker"
                     :value="false"/>
              <label for="patient.smoker.false">Nein</label>
            </div>
          </div>
          <div v-if="$v.patient.smoker.$dirty">
            <small v-if="$v.patient.smoker.$error">
              Bitte geben Sie an, ob die betroffene Person Raucher ist.
            </small>
          </div>
        </sui-form-fields>
      </sui-form-field>
      <sui-form-field :width="5" required
                      :class="{ error: $v.patient.contagiousDiseaseInfection.$error }">
        <label>Aktuell ansteckende Krankheit/Infektion</label>
        <sui-form-fields grouped>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="patient.contagiousDiseaseInfection.true"
                     type="radio"
                     v-model="patient.contagiousDiseaseInfection"
                     :value="true"/>
              <label for="patient.contagiousDiseaseInfection.true">Ja</label>
            </div>
          </div>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="patient.contagiousDiseaseInfection.false"
                     type="radio"
                     v-model="patient.contagiousDiseaseInfection"
                     :value="false"/>
              <label for="patient.contagiousDiseaseInfection.false">Nein</label>
            </div>
          </div>
          <div v-if="$v.patient.contagiousDiseaseInfection.$dirty">
            <small v-if="$v.patient.contagiousDiseaseInfection.$error">
              Bitte geben Sie an, ob eine aktuelle Krankheit/Infektion vorliegt.
            </small>
          </div>
        </sui-form-fields>
      </sui-form-field>
    </sui-form-fields>

    <sui-form-fields>
      <sui-form-field :width="4" required
                      :class="{ error: $v.patient.restrictionMovement.$error }">
        <label>Schwere Bewegungseinschränkung</label>
        <sui-form-fields grouped>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="patient.restrictionMovement.true"
                     type="radio"
                     v-model="patient.restrictionMovement"
                     :value="true"/>
              <label for="patient.restrictionMovement.true">Ja</label>
            </div>
          </div>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="patient.restrictionMovement.false"
                     type="radio"
                     v-model="patient.restrictionMovement"
                     :value="false"/>
              <label for="patient.restrictionMovement.false">Nein</label>
            </div>
          </div>
          <div v-if="$v.patient.restrictionMovement.$dirty">
            <small v-if="$v.patient.restrictionMovement.$error">
              Bitte geben Sie an, ob eine schwere Bewegungseinschränkung vorliegt.
            </small>
          </div>
        </sui-form-fields>
      </sui-form-field>
      <sui-form-field :width="4" required
                      :class="{ error: $v.patient.ventilationNecessary.$error }">
        <label>Notwendigkeit der Beatmung
          <sui-popup>
              <sui-popup-content>
                <p>Hier ist ausschließlich die invasive Beatmung durch Beatmungsgeräte gemeint, 
                  bei der künstliche Atemwege als Schläuche in den Körper eingeführt werden.
                </p>
              </sui-popup-content>
              <i slot="trigger"
                 class="small circular grey inverted question icon"
                 style="margin-left: 5px"></i>
            </sui-popup>
        </label>
        <sui-form-fields grouped>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="patient.ventilationNecessary.true"
                     type="radio"
                     v-model="patient.ventilationNecessary"
                     :value="true"/>
              <label for="patient.ventilationNecessary.true">Ja</label>
            </div>
          </div>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="patient.ventilationNecessary.false"
                     type="radio"
                     v-model="patient.ventilationNecessary"
                     :value="false"/>
              <label for="patient.ventilationNecessary.false">Nein</label>
            </div>
          </div>
          <div v-if="$v.patient.ventilationNecessary.$dirty">
            <small v-if="$v.patient.ventilationNecessary.$error">
              Bitte geben Sie an, ob eine Beatmung notwendig ist.
            </small>
          </div>
        </sui-form-fields>
      </sui-form-field>
    </sui-form-fields>

    <h3 class="ui header">Pflegeart</h3>

    <sui-form-fields>
      <sui-form-field :width="5" required
                      :class="{ error: $v.patient.careType.$error }">
        <label>Anmeldung für&nbsp;&hellip;</label>
        <sui-form-fields grouped>
          <sui-form-field>
            <sui-checkbox v-model="patient.careType"
                          label="Kurzzeitpflege"
                          value="SHORT_TERM"
                          @change="onCareTypeChanged"
                          radio/>
          </sui-form-field>
          <sui-form-field>
            <sui-checkbox v-model="patient.careType"
                          label="Dauerpflege"
                          value="PERMANENT"
                          @change="onCareTypeChanged"
                          radio/>
          </sui-form-field>
          <sui-form-field>
            <sui-checkbox v-model="patient.careType"
                          label="Vorsorgliche Anmeldung"
                          value="PROVISIONAL"
                          @change="onCareTypeChanged"
                          radio/>
            <sui-popup>
              <sui-popup-content>
                <p>Dies trifft zu, wenn Sie noch keinen akuten Bedarf für einen Heimplatz haben,
                  sondern sich nur rein vorsorglich für einen Heimplatz vormerken möchten.</p>
              </sui-popup-content>
              <i slot="trigger"
                 class="small circular grey inverted question icon"
                 style="margin-left: 5px"></i>
            </sui-popup>
          </sui-form-field>
        </sui-form-fields>
        <div v-if="$v.patient.careType.$dirty">
          <small v-if="$v.patient.careType.$error">
            Bitte wählen Sie die gewünschte Pflegeart aus.
          </small>
        </div>
      </sui-form-field>
      <sui-form-field :width="6"
                      :required="patient.careType === 'SHORT_TERM'"
                      :disabled="patient.careType !== 'SHORT_TERM'"
                      :class="{ error: $v.patient.shortTermCare.$error }">
        <label>Wurde die Kurzzeitpflege in diesem Kalenderjahr bereits in Anspruch genommen?</label>
        <sui-form-fields inline>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="patient.shortTermCare.true"
                    :tabindex="(this.patient.careType === 'SHORT_TERM') ? null : -1"
                     type="radio"
                     v-model="patient.shortTermCare"
                     :value="true"/>
              <label for="patient.shortTermCare.true">Ja</label>
            </div>
          </div>
          <div class="field">
            <div class="ui radio checkbox">
              <input id="patient.shortTermCare.false"
                    :tabindex="(this.patient.careType === 'SHORT_TERM') ? null : -1"
                     type="radio"
                     v-model="patient.shortTermCare"
                     :value="false"/>
              <label for="patient.shortTermCare.false">Nein</label>
            </div>
          </div>
        </sui-form-fields>
        <div v-if="$v.patient.shortTermCare.$dirty">
          <small v-if="$v.patient.shortTermCare.$error">
            Bitte geben Sie an, ob die betroffene Person in diesem Kalenderjahr bereits die
            Kurzzeitpflege in Anspruch genommen hat.
          </small>
        </div>
      </sui-form-field>
    </sui-form-fields>
    <sui-form-fields>
      <sui-form-field :width="5" required
                      :class="{ error: $v.patient.careLevelStatus.$error }">
        <label>Pflegegrad-Status</label>
        <sui-form-fields grouped>
          <sui-form-field v-for="option in careLevelStatusOptions"
                          :key="option.value" @change="onCareLevelChanged(option.value)">
            <sui-checkbox v-model="patient.careLevelStatus"
                          :label="careLevelStatusText(option.value)"
                          :value="option.value"
                          radio/>
          </sui-form-field>
        </sui-form-fields>
        <small v-if="$v.patient.careLevelStatus.$error">
          Bitte wählen Sie den Pflegegrad-Status aus.
        </small>
      </sui-form-field>
      <sui-form-field :width="4"
                      :required="isCareLevelRequired()"
                      :disabled="!isCareLevelRequired()"
                      :class="{ error: $v.patient.careLevel.$error }">
        <label>Aktuell bescheinigter Pflegegrad</label>
        <sui-dropdown
          fluid
          search
          :width="3"
          :options="careLevelOptions"
          :tabindex="(isCareLevelSinceEnabled()) ? 0 : -1 "
          placeholder="Bitte auswählen"
          selection
          v-model="patient.careLevel"/>
        <small v-if="$v.patient.careLevel.$error">Bitte wählen Sie den Pflegegrad aus.</small>
      </sui-form-field>
      <sui-form-field :width="3"
                      :disabled="!isCareLevelSinceEnabled()"
                      :class="{ error: $v.patient.careLevelSince.$error }">
        <label>Einstufung seit</label>
        <sui-input
          type="text"
          placeholder="TT.MM.JJJJ"
          :tabindex="(isCareLevelSinceEnabled()) ? null : -1 "
          v-model="careLevelSince"
          @input="value => storeFormattedDate('careLevelSince', value)"/>
        <small v-if="$v.patient.careLevelSince.$error">Bitte geben Sie ein gültiges Datum an.</small>
      </sui-form-field>
    </sui-form-fields>

    <h3 class="ui header">Aufnahme</h3>

    <sui-form-fields>
      <sui-form-field :width="3"
                      :required="isPlacementSubsectionEnabled() && !patient.placementRequiredAsap"
                      :disabled="!isPlacementSubsectionEnabled() || patient.placementRequiredAsap"
                      :class="{ error: $v.patient.desiredPlacementDate.$error }">
        <label>Aufnahme gewünscht zum</label>
        <sui-input
          placeholder="TT.MM.JJJJ"
          :tabindex="(patient.placementRequiredAsap) ? -1 : null "
          type="text"
          v-model="desiredPlacementDate"
          @input="value => storeFormattedDate('desiredPlacementDate', value)"/>
        <small v-if="$v.patient.desiredPlacementDate.$error">
          Bitte geben Sie ein gültiges Aufnahmedatum in der Zukunft an.
        </small>
      </sui-form-field>
    </sui-form-fields>

    <sui-form-fields>
      <sui-form-field :width="10"
                      :disabled="!isPlacementSubsectionEnabled()">
        <sui-checkbox
        @change="onPlacementChanged"
          v-model="patient.placementRequiredAsap"
          label="Schnellstmögliche Aufnahme erforderlich"/>
      </sui-form-field>
    </sui-form-fields>

    <sui-form-fields>

      <sui-form-field :required="isPlacementSubsectionEnabled() && patient.placementRequiredAsap"
            :disabled="!isPlacementSubsectionEnabled() || !patient.placementRequiredAsap"
            :class="{ error: $v.patient.placementRequiredAsapReason.$error }">
        <label>Begründung</label>
        <sui-dropdown
            fluid
            search
            :tabindex="(patient.placementRequiredAsap) ? null : -1"
            :options="placementAsapReasons"
            placeholder="Bitte Grund auswählen"
            selection
            v-model="patient.placementRequiredAsapReason"/>
        <small v-if="$v.patient.placementRequiredAsapAddition.$error">
          Bitte geben Sie einen Grund an, weshalb eine schnellstmögliche Aufnahme erforderlich ist.
        </small>
      </sui-form-field>

      <sui-form-field :width="11" v-if="isOtherPlacementReason()"
            :required="isPlacementSubsectionEnabled() && patient.placementRequiredAsap && isOtherPlacementReason"
            :disabled="!isPlacementSubsectionEnabled() || !patient.placementRequiredAsap"
            :class="{ error: $v.patient.placementRequiredAsapAddition.$error }">
        <label>Ergänzung</label>
        <sui-input
          placeholder="Ergänzung"
          :tabindex="(patient.placementRequiredAsap) ? 0 : -1"
          v-model="patient.placementRequiredAsapAddition"/>
        <small v-if="$v.patient.placementRequiredAsapAddition.$error">
          Bitte ergänzen Sie, weshalb eine schnellstmögliche Aufnahme erforderlich ist.
        </small>
      </sui-form-field>

    </sui-form-fields>

    <h3 class="ui header">Derzeitige Versorgung</h3>

    <sui-form-fields>
      <sui-form-field :width="4" required
                      :class="{ error: $v.patient.currentCaregiver.$error }"
                      @change="onCareTypeChanged">
        <label>Versorgung</label>
        <sui-dropdown
          fluid
          search
          :options="caregiverOptions"
          placeholder="Bitte auswählen"
          selection
          v-model="patient.currentCaregiver"
          />
        <small v-if="$v.patient.currentCaregiver.$error">
          Bitte wählen Sie die Art der derzeitigen Versorgung aus.
        </small>
      </sui-form-field>
      <sui-form-field :width="7"
                      :required="isCurrentCareFacilityRequired()"
                      :disabled="!isCurrentCareFacilityRequired()"
                      :class="{ error: $v.patient.currentCareFacility.$error }">
        <label>Einrichtung, Dienst

        <sui-popup>
          <sui-popup-content>
            <p>Sie können eine Einrichtung oder einen Dienst aus dem Dropdown auswählen
               oder einen Freitext eingeben und diesen per Enter bestätigen.</p>
          </sui-popup-content>
          <i slot="trigger"
              class="small circular grey inverted question icon"
              style="margin-left: 5px"></i>
        </sui-popup>

        </label>
        
        <sui-dropdown
          fluid
          search
          selection
          allow-additions
          multiple
          :options="nursingServices"
          placeholder="Einrichtung, Dienst"
          v-model="patient.currentCareFacility" />
        <small v-if="$v.patient.currentCareFacility.$error">
          Bitte geben Sie die versorgende Einrichtung an.
        </small>
      </sui-form-field>
    </sui-form-fields>
  </div>
</template>

<script>
  import { required, requiredIf, minLength, maxLength } from "vuelidate/lib/validators";
  import retirementHomeService from "@/services/retirementHomeService";
  import moment from 'moment';
  import selectOptions from '@/assets/selectOptions.json'
  import selectOptionsMixin from '@/mixins/selectOptionsMixin';
  import checkDateMixin from '@/mixins/checkDateMixin';

  import _ from "lodash"

  const isCurrentCareFacilityRequired = (currentCaregiver) => {
    return !['NONE', 'RELATIVES'].includes(currentCaregiver);
  };

  const isCareLevelRequired = (careLevelStatus) => {
    return ['ASSIGNED', 'UPGRADE_PENDING', 'PROVISIONAL'].includes(careLevelStatus);
  };

  const requiredIfCareLevelStatusIsAssigned = requiredIf(function () {
    return isCareLevelRequired(this.patient.careLevelStatus);
  });
 
  function checkDateOfBirth() {
    return this.isValidDateFormat(this.dateOfBirth, false);
  }

  function checkCareLevelSince() {
    if(!this.careLevelSince) {
      return true
    } else {
       return this.isValidDateFormat(this.careLevelSince, false) 
    }
  }
  
  function checkFutureDesiredPlacementDate() {
    if(this.isPlacementSubsectionEnabled() && !this.patient.placementRequiredAsap) {
      return this.isValidDateFormat(this.desiredPlacementDate, true);
    }
    return true //validating true if not required.
  }
  
  export default {
    name: 'PatientSection',
    mixins: [
      selectOptionsMixin,
      checkDateMixin
    ],
    props: {
      value: {
        type: Object,
        required: true
      }
    },
    methods: {
      storeFormattedDate(fieldName, value){
        let date = moment(value, 'DD.MM.YYYY');
        if(date.isValid()) {
          this.patient[fieldName] = date.format('YYYY-MM-DD');
        }else{
          this.patient[fieldName] = null;
        }
        
      },
      formatDate(date){
        if(date == null) return;
        let formattedDate = moment(date, 'YYYY-MM-DD');

        if(formattedDate != null && formattedDate.isValid()) return formattedDate.format("DD.MM.YYYY");
        return moment(date).format("DD.MM.YYYY");
      },
      onCareLevelChanged(careLevel){
        if(careLevel === 'NONE' || careLevel === 'PENDING'){
          this.careLevelSince = null;
          this.patient.careLevelSince = null;
          this.patient.careLevel = null;
        }
      },
      onPlacementChanged(){
        this.desiredPlacementDate = null;
        this.patient.desiredPlacementDate = null;
        this.patient.placementRequiredAsapReason = null;
      },
      onCareTypeChanged() {
        this.patient.shortTermCare = null; // SKH-229
        if (this.patient.careType === 'PROVISIONAL') {
          this.patient.desiredPlacementDate = null;
          this.patient.placementRequiredAsap = false;
          this.patient.placementRequiredAsapReason = null;
        }
      },
      onCurrentCaregiverChange(){
        this.patient.currentCareFacility = [];
      },
      isCareLevelRequired() {
        return isCareLevelRequired(this.patient.careLevelStatus);
      },
      careLevelStatusText(value) {
        let text = this.optionText(this.careLevelStatusOptions, value);
        if (isCareLevelRequired(value)) {
          text += ' (bitte angeben)';
        }
        return text;
      },
      isCurrentCareFacilityRequired() {
        return isCurrentCareFacilityRequired(this.patient.currentCaregiver);
      },
      isContactDesired() {
        return this.patient.contactDesired;
      },
      isPlacementSubsectionEnabled() {
        return this.patient.careType != 'PROVISIONAL';
      },
      isCareLevelSinceEnabled() {
        // SKH-332 The date input field should be enabled when the care level field is ebabled.
        return isCareLevelRequired(this.patient.careLevelStatus);
      },
      isOtherPlacementReason() {
        return this.patient.placementRequiredAsapReason === 'OTHER';
      }
    },
    computed: {
      patient: {
        get() {
          return this.value;
        },
        set(value) {
          this.v.$touch();
          this.$emit('input', value);
        }
      },
      careLevel() {
        return this.patient.careLevel;
      },
      careLevelStatus() {
        return this.patient.careLevelStatus;
      }
    },
    data() {
      return {
        careLevelSince:null,
        desiredPlacementDate:null,
        dateOfBirth:null,
        nursingServices: [],
        future: true,
        denominationOptions: _.orderBy(selectOptions.denominationOptions, "type", "asc"),
        citizenshipOptions: selectOptions.citizenshipOptions,
        placementAsapReasons: selectOptions.placementAsapReasons,
        careLevelStatusOptions: selectOptions.careLevelStatusOptions,
        careLevelOptions: selectOptions.careLevelOptions,
        caregiverOptions: selectOptions.caregiverOptions,
        genderOptions: selectOptions.genderOptions,
        contactDesiredInfo: 'Ist eine direkte Kontaktaufnahme zur betroffenen Person erwünscht? ' +
          'Falls nein, so können Sie später im Formularteil „Anmeldende Person“ noch einen ' +
          'anderen Kontakt angegeben.',
        runTendencyInfo: 'Menschen können in ihrer ' +
          'Orientierung und Alltagskompetenz so stark beeinträchtigt sein, dass man sie vor Weg- ' +
          'und Hinlauf-Situationen schützen muss. In diesen Einrichtungen gibt es spezielle ' +
          'Türsicherungen. Diese Türen kann man nur öffnen, wenn man zum Beispiel einen ' +
          'hinterlegten Zahlencode in eine Tastatur eingibt.\n\n' +
          'Der starke Bewegungsdrang kann meist durch die Einrichtung geschützter Gärten oder ' +
          'entsprechenden „Laufflächen“ (Gänge, Flure) kompensiert werden.',
          datesFormatted: false
      };
    },
    mounted(){
      retirementHomeService.getNursingServicesOptions()
      .then(result => {this.nursingServices = result.data;});
    },
    beforeUpdate(){
      if(!this.datesFormatted){
        if(this.patient.dateOfBirth != null)
          this.dateOfBirth = this.formatDate(this.patient.dateOfBirth);
        if(this.patient.desiredPlacementDate != null)
          this.desiredPlacementDate = this.formatDate(this.patient.desiredPlacementDate);
        if(this.patient.careLevelSince != null)
          this.careLevelSince = this.formatDate(this.patient.careLevelSince);
        
        this.datesFormatted = true;
      }
    },
    watch: {
      'patient.currentCareFacility': function (newValue) {
        if(newValue && newValue.length > 1){
          this.patient.currentCareFacility = [];
          this.patient.currentCareFacility.push(newValue[ newValue.length-1 ] );
        }
      },
      'patient.currentCaregiver': function (newValue) {
        if(newValue && newValue.length > 1){
          if(newValue === 'RELATIVES' || newValue === 'NONE'){
            this.patient.currentCareFacility = [];
          }
        }
      },
      'patient.dateOfBirth': function (val) {
        if(val && this.dateOfBirth == null){
          this.dateOfBirth = this.formatDate(val);
        }
      },
      'patient.careLevelSince': function (val) {
        if(val && this.careLevelSince == null){
          this.careLevelSince = this.formatDate(val);
        }
      },
      'patient.desiredPlacementDate': function (val) {
        if(val && this.desiredPlacementDate == null){
          this.desiredPlacementDate = this.formatDate(val);
        }
      },
    },
    validations: {
      patient: {
        firstName: {
          required,
          minLength: minLength(2)
        },
        lastName: {
          required,
          minLength: minLength(2)
        },
        gender: {
          required
        },
        dateOfBirth: {
          required,
          checkDateOfBirth
        },
        address: {
          required,
          minLength: minLength(4)
        },
        postalCode: {
          required,
          minLength: minLength(5),
          maxLength: maxLength(5)
        },
        city: {
          required,
          minLength: minLength(2)
        },
        citizenship: {
          required
        },
        careLevelStatus: {
          required
        },
        careLevel: {
          required: requiredIfCareLevelStatusIsAssigned
        },
        careType: {
          required: requiredIf(function () {
            return this.patient.careType != 'PROVISIONAL';
          })
        },
        careLevelSince: {
          checkCareLevelSince
        },
        shortTermCare: {
          required: requiredIf(function () {
            return this.patient.careType === 'SHORT_TERM';
          })
        },
        desiredPlacementDate: {
          required: requiredIf(function () {
            return this.isPlacementSubsectionEnabled() && !this.patient.placementRequiredAsap;
          }),
          checkFutureDesiredPlacementDate
        },
        placementRequiredAsapReason: {
          required: requiredIf(function () {
            return this.patient.placementRequiredAsap;
          })
        },
        placementRequiredAsapAddition: {
          required: requiredIf(function () {
            return this.patient.placementRequiredAsap && this.isOtherPlacementReason();
          })
        },
        currentCaregiver: {
          required
        },
        currentCareFacility: {
          required: requiredIf(function () {
            return isCurrentCareFacilityRequired(this.patient.currentCaregiver)
          }),
          minLength(value){
            if(value && value[0]){
              return value[0].length >= 3;
            }
            return true;
          }
        },
        runTendency: {
          required
        },
        smoker: {
          required
        },
        dementia: {
          required
        },
        contactDesired: {
          required
        },
        phone: {
          required: requiredIf(function () {
            return this.isContactDesired();
          })
        },
        contagiousDiseaseInfection: {
          required
        },
        restrictionMovement: {
          required
        },
        ventilationNecessary: {
          required
        }
      }
    }
  };
</script>
