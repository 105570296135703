<template>
  <div>
    <h2 class="ui dividing header">Finanzierung</h2>

    <sui-form-field required
                    :class="{ error: $v.financing.selfPayer.$error }">
      <label>Selbstzahler</label>
      <sui-form-fields inline>
        <!-- sui-checkbox will not work with v-bind:value -->
        <div class="field">
          <div class="ui radio checkbox">
            <input id="selfPayerTrue" type="radio" v-model="financing.selfPayer" :value="true"/>
            <label for="selfPayerTrue">Ja</label>
          </div>
        </div>
        <div class="field">
          <div class="ui radio checkbox">
            <input id="selfPayerFalse" type="radio" v-model="financing.selfPayer" :value="false"/>
            <label for="selfPayerFalse">Nein</label>
          </div>
        </div>
        <div v-if="$v.financing.selfPayer.$dirty">
          <small v-if="$v.financing.selfPayer.$error">
            Bitte geben Sie an, ob die Pflege aus eigenen Mitteln finanziert werden soll.
          </small>
        </div>
      </sui-form-fields>
    </sui-form-field>

    <sui-form-fields>
      <sui-form-field :width="5"
                      required
                      :class="{ error: $v.financing.insurance.$error }">
        <label>Kranken- bzw. Pflegeversicherung</label>
        <sui-input
          placeholder="Kranken- bzw. Pflegeversicherung"
          v-model="financing.insurance"/>
        <small v-if="$v.financing.insurance.$error">
          Bitte geben Sie die Kranken- bzw. Pflegeversicherung ein.
        </small>
      </sui-form-field>
      <sui-form-field :width="4"
                      :required="!financing.selfPayer"
                      :disabled="financing.selfPayer"

                      :class="{ error: $v.financing.socialWelfareSupportType.$error }">
        <label>Unterstützung durch die Sozialhilfe</label>
        <sui-dropdown
          fluid
          :options="socialWelfareSupportTypeOptions"
          :tabindex="(financing.selfPayer)? -1 : 0 "
          placeholder="Bitte auswählen"
          selection
          v-model="financing.socialWelfareSupportType"/>
        <small v-if="$v.financing.socialWelfareSupportType.$error">
          Bitte wählen Sie die Art der Unterstützung durch die Sozialhilfe aus.
        </small>
      </sui-form-field>
      <sui-form-field :width="5"
                      :required="socialWelfareOfficeRequired"
                      :disabled="!socialWelfareOfficeRequired"
                      :class="{ error: $v.financing.socialWelfareOffice.$error }">
        <label>Zuständiges Sozialamt</label>
        <sui-input
          :tabindex="(socialWelfareOfficeRequired) ? null : -1 "
          placeholder="Zuständiges Sozialamt"
          v-model="financing.socialWelfareOffice"/>
        <small v-if="$v.financing.socialWelfareOffice.$error">
          Bitte geben Sie das zuständige Sozialamt ein.
        </small>
      </sui-form-field>
    </sui-form-fields>

    <sui-form-fields>
      <sui-form-field :width="9">
        <label>Hinweise, Ergänzungen</label>
        <sui-input
          placeholder="Hinweise, Ergänzungen"
          v-model="financing.comments"/>
      </sui-form-field>
    </sui-form-fields>
  </div>
</template>

<script>
  import { required, requiredIf } from "vuelidate/lib/validators";

  import selectOptions from '@/assets/selectOptions';
  
  const isNonSelfPayerFieldRequired = requiredIf(function () {
    return this.financing.selfPayer === true;
  });

  export default {
    name: 'FinancingSection',
    props: {
      value: {
        type: Object,
        required: true
      }
    },
    computed: {
      financing: {
        get() {
          return this.value;
        },
        set(value) {
          this.v.$touch();
          this.$emit('input', value);
        }
      },
      socialWelfareOfficeRequired() {
        return ['RECEIVES', 'APPLIED'].includes(this.financing.socialWelfareSupportType);
      }
    },
    methods: {
      resetSocialWelfareSupportType(val) {
        if (val) {
          this.financing.socialWelfareSupportType = 'NONE';
          this.financing.socialWelfareOffice = '';
        }
      }
    },
    data() {
      return {
        socialWelfareSupportTypeOptions: selectOptions.socialWelfareSupportTypeOptions
      };
    },
    watch: {
      'financing.selfPayer': 'resetSocialWelfareSupportType',
      'financing.socialWelfareSupportType': function (newValue) {
        if(newValue && newValue.length > 1){
          if(newValue === 'NONE'){
            this.financing.socialWelfareOffice = '';
          }
        }
      }
    },
    validations: {
      financing: {
        selfPayer: {
          required
        },
        insurance: {
          required
        },
        socialWelfareSupportType: {
          required: isNonSelfPayerFieldRequired
        },
        socialWelfareOffice: {
          required: isNonSelfPayerFieldRequired && requiredIf(function () {
            return this.financing.socialWelfareSupportType &&
                    this.financing.socialWelfareSupportType !== 'NONE';
          })
        }
      }
    }
  };
</script>
