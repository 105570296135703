<template>
  <div>
    <h2 class="ui dividing header">Nachweise und Dokumente</h2>
    
    <sui-segment class="placeholder">
      <sui-header icon>
        <i class="file outline icon"></i>
        {{ documentsMessage }}
      </sui-header>

      <sui-segments v-if="files.length">
        <sui-segment v-for="(file, index) in files"
                     :key="index">
          <a @click.prevent="files.splice(index, 1)">
            <i class="black close icon"></i>
          </a>
          <span class="filename">{{ file.name }}</span>
        </sui-segment>
      </sui-segments>

      <label>
        <span class="ui button green">Dokumente hinzufügen</span>
        <input multiple type="file" accept=".doc,.docx,.pdf"
               ref="files" @change="selectFile"/>
      </label>
    </sui-segment>

    <sui-message>
      <sui-message-header>Hinweis</sui-message-header>
      <sui-message-content>
        Es werden keine Dokumente zwingend benötigt. Wenn Sie möchten, können Sie aber bereits jetzt
        Dokumente wie z.&nbsp;B. die Bescheinigungen über den Pflegegrad, Vollmachten, ärztliche
        Atteste usw. im PDF-Format Ihrer Anmeldung hinzufügen.
      </sui-message-content>
    </sui-message>
  </div>
</template>

<script>
  import _ from 'lodash';

  export default {
    name: 'DocumentSection',
    props: {
      value: {
        type: Object,
        required: true
      },
    },
    computed: {
      files: {
        get() {
          return this.value.files;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
      documents: {
        get() {
          return this.value.documents;
        },
        set(value) {
          this.$emit('input', value);
        }
      },
      documentsMessage: function () {
        if (!this.value.files.length) {
          return 'Es wurden noch keine Dokumente zu dieser Anmeldung hinzugefügt.';
        }
        return 'Folgende Dokumente wurden zu dieser Anmeldung hinzugefügt:';
      }
    },
    methods: {
      replaceUmlaute(str) {
        str = str.replace('Ü', "UE")
                 .replace('ü', "ue")
                 .replace('Ä', "AE")
                 .replace('ä', "ae")
                 .replace('Ö', "OE")
                 .replace('ö', "oe")
                 .replace('ß', "ss");/*  */
        return str;
      },
      selectFile() {
        Array.from(this.$refs.files.files)
          .forEach(uploadedFile => {
            let fileName = this.replaceUmlaute(uploadedFile.name);
            let tmpFile = new File([uploadedFile], fileName, {type: uploadedFile.type});
            let file = _.pick(tmpFile, ['name', 'size', 'type']);
            file.name = tmpFile.name;
            this.files.push(file);
            this.documents.push(tmpFile);
          });
      }
    },
    // Each section component must define a validations object, even when there
    // are no input fields to be validated.
    validations: {
    }
  }
</script>

<style scoped>
  .icon.close {
    float: right;
  }

  input[type="file"] {
    display: none;
  }
</style>
