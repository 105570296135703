<template>
  <div>
    <h2 class="ui dividing header">Zusammenfassung Ihres Antrags</h2>

    <ApplicationSummary :application="application"
                        :institution-options="institutionOptions"/>

    <sui-segment>
      <sui-form-field required
                      :class="{ error: $v.privacyPolicyAccepted.$error }">
        <label>Datenschutzerklärung</label>
        <sui-form-field>
          <sui-checkbox v-model="privacyPolicyAccepted">
            <p>Ich bin damit einverstanden, dass die gemachten Angaben durch die von mir
              ausgewählten Einrichtungen zur Vermittlung eines Heimplatzes eingesehen werden
              können.</p>

            <p>Personenbezogene Daten werden gelöscht, sobald die betroffene Person in eine
              Einrichtung eingezogen ist und die Angaben für eine weitere Vermittlung nicht mehr
              erforderlich sind.</p>

            <p>Solange die betroffene Person noch auf einen Heimplatz wartet, wird in einem
              jährlichen Turnus abgefragt, ob der Bedarf nach einem Heimplatz weiterhin besteht. Die
              Erinnerung an diese Erneuerung der Anmeldung erfolgt automatisiert über die
              hinterlegte E-Mail-Adresse. Wenn ich innerhalb von drei Monaten nach erneuter Abfrage
              des Heimplatzbedarfs nicht reagiere, werden die hinterlegten personenbezogenen Daten
              ebenfalls gelöscht.</p>

            <p>Außerdem können die Angaben jederzeit durch mich proaktiv unter
               <router-link to="/loadExistingApplication" target="_blank">diesem Link</router-link>
              oder auf meinen ausdrücklichen Wunsch gegenüber der zentralen Stelle der Kommune,
              welche unter der E-Mail-Adresse
              <a href="mailto:marianne.stumpf@konstanz.de">marianne.stumpf@konstanz.de</a>
              erreichbar ist, oder gegenüber einer von mir ausgewählten Einrichtung gelöscht
              werden.</p>
          </sui-checkbox>
        </sui-form-field>
      </sui-form-field>
      <div v-if="$v.privacyPolicyAccepted.$dirty">
        <small>Um fortzufahren, müssen Sie die Datenschutzerklärung akzeptieren.</small>
      </div>

      <p>Sollten Sie der Einwilligung nicht zustimmen, können Sie an diesem Verfahren nicht
        teilnehmen. Sie können sich dann direkt mit der Servicestelle in Verbindung setzen.</p>

      <p>Es gelten weiterhin die übergeordneten
        <a href="https://www.konstanz.de/18937.html"
           target="_blank"
           rel="noopener noreferrer">Datenschutzbestimmungen</a>
        der Stadt Konstanz.</p>
    </sui-segment>
  </div>
</template>

<script>
  import sameAs from 'vuelidate/lib/validators/sameAs';

  import ApplicationSummary from "@/components/ApplicationSummary";

  export default {
    name: 'ApplicationSummarySection',
    components: {
      ApplicationSummary,
    },
    props: {
      application: {
        type: Object,
        required: true
      },
      institutionOptions: {
        type: Array,
        required: true
      }
    },
    data() {
      return {
        privacyPolicyAccepted: false
      }
    },
    validations: {
      privacyPolicyAccepted: {
        sameAs: sameAs(() => true)
      }
    }
  }
</script>
