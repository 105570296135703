<template>
  <div>
    <sui-container text>
      <h2 class="ui header">
        Vorhandene Heimplatzanmeldung einsehen / bearbeiten
      </h2>

      <p>
        Auf dieser Seite können Sie den Status Ihrer Heimplatzanmeldung
        einsehen, Angaben zu Ihrer bereits übermittelten Heimplatzanmeldung
        bearbeiten, Ihre Heimplatzanmeldung verlängern oder melden, dass Sie
        keinen Bedarf mehr an einem Heimplatz haben.
      </p>

      <p>
        Zur Identifikation benötigen Sie lediglich die Chiffre-Nummer, welche
        Ihnen bei der ursprünglichen Anmeldung mitgeteilt wurde, sowie das
        Geburtsdatum der betroffenen Person.
      </p>
    </sui-container>

    <sui-container text>
      <sui-grid>
        <sui-grid-column :width="8">
          <application-id-input-box
            ref="applicationIdInputBox"
            @submitApplicationId="submit($event)"
          />
        </sui-grid-column>
      </sui-grid>

      <sui-message v-if="status === 'error'" negative>
        {{ errorMessage }}
      </sui-message>
    </sui-container>
  </div>
</template>

<script>

import ApplicationIdInputBox from "@/components/ApplicationIdInputBox";

export default {
  components: {
    ApplicationIdInputBox,
  },
  data() {
    return {
      status: "",
      errorMessage: "",
    };
  },
  methods: {
    submit(event) {
      const { applicationId, patientDob } = event; // TODO rename patientDob
      const birthDate = patientDob;

      this.$router.push({
        name: "dashboard",
        query: {
          aid: applicationId,
          bd: birthDate,
        },
      });
    },
  },
};
</script>

<style>
.container {
  padding: 0 0 2em 0;
}
</style>
