<template>
  <sui-container id="main">
    <loading :active='isLoading' :is-full-page="fullPage" loader="bars" color="#d52b1e"/>
    <div v-if="currentStep !== 6" style="float: right">
      <span style="color: #9f3a38">*</span> = Pflichtfeld
    </div>
    <h1 class="ui header" style="margin-bottom: 1em">Heimplatzanmeldung</h1>

    <div v-if="error" class="ui small error message" style="margin-bottom: 3em">
      <div class="header">
        Ihre Eingaben sind leider fehlerhaft bzw. ungültig. Bitte korrigieren
        Sie Ihre Eingaben in den rot hervorgehobenen Feldern.
      </div>
    </div>



    <vue-semantic-modal v-model="showFileUploadErrorModal" :close-on-click-away="true">
      <p slot="header">Dokumenten-Upload fehlgeschlagen!</p>
      <template slot="content">
        <p>Der Upload ihrer Dokumente ist leider fehlgeschlagen. Die Anmeldung ist trotzdem erfolgreich bei uns eingegangen.</p>
        <p>Sie können die Bewerbung bearbeiten und die Dokumente nochmals ihrer Bewerbung hinzufügen.</p>
      </template>
      <template slot="actions">
        <div class="ui positive right button" @click="showFileUploadErrorModal=false">
          Verstanden
        </div>
      </template>
    </vue-semantic-modal>

    <div v-if="!applicationSent" class="ui form">
      <FormSteps
        :steps="steps"
        :steps-classes="['seven', 'small']"
        :on-next="nextClicked"
        :on-back="previousClicked"
        @submit="handleSubmit"
      >
        <div slot="step-patient">
          <PatientSection v-model="application.patient" ref="patient" />
        </div>
        <div slot="step-applicant">
          <ApplicantSection v-model="application.applicant" ref="applicant" />
        </div>
        <div slot="step-doctor">
          <DoctorSection v-model="application.doctor" ref="doctor" />
        </div>
        <div slot="step-financing">
          <FinancingSection v-model="application.financing" ref="financing" />
        </div>
        <div slot="step-institutionSelection">
          <InstitutionSection
            v-model="application.institutionSelection"
            ref="institutionSelection"
            :institution-options="institutionOptions"
          />
        </div>
        <div slot="step-documents">
          <DocumentSection v-model="application.documents" ref="documents" />
        </div>
        <div slot="step-summary">
          <SummarySection
            :application="application"
            ref="summary"
            :institution-options="institutionOptions"
          />
        </div>
      </FormSteps>
    </div>

    <confirmation-message
      v-else
      :application-id="applicationId"
      :patient-dob="application.patient.dateOfBirth"
      :fileUploadFailed="fileUploadFailed"
    />
  </sui-container>
</template>

<script>
import RetirementHomeService from "@/services/retirementHomeService";

import PatientSection from "@/components/application/sections/PatientSection";
import ApplicantSection from "@/components/application/sections/ApplicantSection";
import DoctorSection from "@/components/application/sections/DoctorSection";
import FinancingSection from "@/components/application/sections/FinancingSection";
import InstitutionSection from "@/components/application/sections/InstitutionSection";
import DocumentSection from "@/components/application/sections/DocumentSection";
import SummarySection from "@/components/application/sections/SummarySection";

import ConfirmationMessage from "@/components/ConfirmationMessage";
import FormSteps from "@/components/FormSteps";

import VueSemanticModal from "vue-semantic-modal";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import moment from 'moment';

import { initialApplicationState } from '@/application'
export default {
  name: "ApplicationForm",
  components: {
    Loading,
    SummarySection,
    PatientSection,
    ApplicantSection,
    DoctorSection,
    FinancingSection,
    InstitutionSection,
    DocumentSection,
    ConfirmationMessage,
    FormSteps,
    VueSemanticModal
  },
  props: {
    processInstanceId: {
      type: String,
    }
  },
  data() {
    return {
      steps: [
        {
          label: "Betroffene Person",
          slot: "step-patient",
        },
        {
          label: "Anmeldende Person",
          slot: "step-applicant",
        },
        {
          label: "Hausarzt",
          slot: "step-doctor",
        },
        {
          label: "Finanzierung",
          slot: "step-financing",
        },
        {
          label: "Einrichtung",
          slot: "step-institutionSelection",
        },
        {
          label: "Dokumente",
          slot: "step-documents",
        },
        {
          label: "Zusammen&shy;fassung",
          slot: "step-summary",
        },
      ],
      institutionOptions: [],
      applicationSent: false,
      applicationId: null,
      currentStep: 0,
      error: false,
      fileUploadFailed: false,
      showFileUploadErrorModal: false,
      isLoading: false
    };
  },
  computed:{
    application(){
      return this.$store.getters.application;
    }
  },
  watch:{
    application:{
      deep:true,
      handler(val){
        this.$store.commit('updateApplication', val);
      }
    },
    applicationSent(val){
      if(val){
        this.isLoading = false;
      }
    }
  },
  methods: {
    loadValuesFromExistingApplication() {
      if (!(JSON.stringify(initialApplicationState()) === JSON.stringify(this.$store.state.application))) {
        this.$store.commit('updateApplication', this.$store.state.application);
        this.application = this.$store.state.application;
        this.$store.state.application.patient.dateOfBirth = moment(this.$store.state.application.patient.dateOfBirth).format("YYYY-MM-DD");
        
        if(this.$store.state.application.patient.careLevelSince){
          var isTimestamp = (new Date(this.$store.state.application.patient.careLevelSince)).getTime() > 0;
          if(isTimestamp)
            this.$store.state.application.patient.careLevelSince = moment(this.$store.state.application.patient.careLevelSince).format("YYYY-MM-DD");
        }
        
        if(Array.isArray(this.application.patient.currentCareFacility)){
          this.$store.state.application.patient.currentCareFacility = this.application.patient.currentCareFacility;
        }else{
          this.$store.state.application.patient.currentCareFacility = [this.application.patient.currentCareFacility];
        }
        
      }
    },
    fetchInstitutionOptions() {
      RetirementHomeService.getRetirementHomeOptions()
        .then((response) => {
          this.institutionOptions = response.data;
        })
        // eslint-disable-next-line no-console
        .catch(console.error);
    },
    validateStep(currentStep) {
      const slot = this.steps[currentStep].slot.substring("step-".length);

      this.$refs[slot].$v.$touch();

      const invalid = this.$refs[slot].$v.$invalid;
      return !invalid;
    },
    // eslint-disable-next-line no-unused-vars
    nextClicked(currentStep) {
      const valid = this.validateStep(currentStep);
      this.error = !valid;

      if (valid) {
        // Method is called before the form goes to the next step.
        this.currentStep = currentStep + 1;
      }
      return valid;
    },
    previousClicked(currentStep) {
      // Method is called before the form goes to the previous step.
      this.currentStep = currentStep - 1;

      return true;
    },
    germanDateFromTime(time) {
      if (time) {
      var date = new Date(time);
      var day = date.getDate()
      var month = parseInt(date.getMonth().toString())+1
      var year = date.getFullYear();
      return day + "." + month + "." + year
      } else {
         return null
      }
    },
    handleSubmit() {
      this.application.patient.applicationType = this.application.patient.careType;
      this.isLoading = true;
      // SKH-232
      if (!this.validateStep(this.currentStep)) {
        this.isLoading = false;
        return;
      }

      if(Array.isArray(this.application.patient.currentCareFacility) && this.application.patient.currentCareFacility.length > 0){
        this.application.patient.currentCareFacility = this.application.patient.currentCareFacility[0]; // only one is allowed in PatientSection
      }else{
        this.application.patient.currentCareFacility = "";
      }

      const documents = this.application.documents.documents;
      delete this.application.documents.documents;

      if (!this.$store.state.passedApplicationId && !this.$store.state.passedBirthDate) {
        RetirementHomeService.createApplication(this.application)
          .then(async (response) => {

            this.applicationId = response.data.applicationId;

            this.uploadFiles(documents)
            
          })
          .catch(() => {
            this.isLoading = false;
          });
      } else {

        const applicationUpdateDto = {
          authentication: {
            birthDate: this.$store.state.passedBirthDate,
            applicationId: this.$store.state.passedApplicationId,
          },
          application: this.application,
        };

        RetirementHomeService.updateApplication(applicationUpdateDto).then(
          async (result) => {

            this.applicationId = result.data.applicationId;
            this.uploadFiles(documents);

          }
        )
        .catch(()=> {
          this.isLoading = false;
        })
        
      }
    },
    uploadFiles(documents){

      let formData = new FormData();

      for(var i =0; i<documents.length; i++){
        formData.append("documents"+i, documents[i]);
      }

      RetirementHomeService.uploadFile(this.applicationId, formData).then(() => {
        this.isLoading = false;
        this.applicationSent = true;
      }).catch(()=>{
        this.isLoading = false;
        this.fileUploadFailed = true;
        this.showFileUploadErrorModal = true;
        this.applicationSent= true;
      });

    },
  },
  mounted() {
    this.fetchInstitutionOptions();
    this.loadValuesFromExistingApplication();
  }
}
</script>
